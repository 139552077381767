<template>
  <div style="width: 100%; height: 100%; overflow-x: hidden">
    <div
      style="width: 100%; height: 100%"
      v-loading="loading"
      element-loading-background="rgba(0, 0, 0, 0.8)">
      <div id="mapGoogle"></div>
    </div>
  </div>
</template>
<script>
import Tool from '@/assets/js/utils'
import { mapState } from 'vuex'
import Bus from '@/assets/js/vueBus.js'

export default {
  props: {
    mapQueryObj: {
      type: Object,
      default: function () {
        return {}
      }
    },
    tracking: Object
  },
  data () {
    return {
      lang: 'en',
      bounds: [],
      map: null,
      isWheel: true,
      wheelZoom: 3,
      timestamps: [],
      maxTime: 0,
      imgUrl: '',
      formDetail: {}, // 存储maker详情
      mapSourceData: [],
      historyFlag: false, // 历史数据flag
      signal: '', // 信号强弱
      windowDetail: {}, // 打开弹框的信息
      saveTime: '',
      interval: '500000_1',
      spaceTime: 5000,
      mapType: '',
      loading: false,
      deafultImg: 'this.src="' + require('@/assets/img/logo_thumbnail.png') + '"',
      url: `${JSON.parse(localStorage.getItem('newCCInitConfig')).urlInfo.frontGeoServiceUrl}`,
      // url: `/geoservice`,
      firstFlag: 0,
      testNum: 0,
      endPoints: [], // 实时记录终点数组
      startPoints: [], // 实时记录起点数组
      manualOrange: [], // 手动打点记录黄色
      manualGreen: [], // 手动打点绿色
      singnalString: '', // 信号显示的问题内容
      iconType: '',
      startNum: 0,
      BMapTimer: 0,
      sourceDataObj: {
        currentrinfo: {
          CurrentTInputType: {},
          CurrentTFormat: {}
        },
        setinfo: {
          CurrentSetting: {}
        },
        receiverdata: {},
        slotinfo: {
          AdapterModels: [{}, {}, {}, {}, {}, {}]
        }
      },
      firstData: [],
      form: {},
      allMarkData: [], // 所有的mark点
      allPositionData: [],
      currentSource: {}, // 当前source的实时信息
      slotDetail: [[], [], [], [], [], []],
      // lastPointTime: "", // 最后一个点的时间戳
      params: {
        peerid: this.$route.query.peerId,
        starttime: Date.parse(new Date(Number(this.$route.query.startTime))).toString(),
        endtime: Date.parse(new Date(Number(this.$route.query.endTime))).toString()
      },
      openTPlatform: null,
      gpsDuration: 120,
      speedInfo: {},
      bitRate: null,
      allPosition: [],
      allDeviceLivePeerId: [],
      isDeviceWindow: false,
      deviceTimeFlag: true,
      mapObj: {},
      markersArray: [],
      deleteStatus: false,
      infoWindow: '',
      singnalArr: []
    }
  },
  created () {
    window.saveMaker = this.saveMaker
    window.deleteMaker = this.deleteMaker
    window.cancelModal = this.cancelModal
    window.editMaker = this.editMaker
    window.extendSourceDetail = this.extendSourceDetail
    window.closeSourceDetail = this.closeSourceDetail
    this.getInterval()
    if (process.env.NODE_ENV === 'development') {
      this.url = '/geoservice'
    }
  },
  mounted () {
    this.getSourceStatus()
    this.loading = true
    this.lang = window.localStorage.getItem('lang') || 'en'
    this.initMap()
  },
  computed: {
    ...mapState({
      StateObj: state => state,
      pageRequest: state => state.websocket.pageRequest,
      pageRWebsocket: state => state.websocket.pageRWebsocket
    })
  },
  beforeDestory () {
    this.iconType = ''
    clearInterval(this.timer)
    if (this.$createWebsocket.pageRequest) {
      this.$createWebsocket.pageRequest('stop', [
        'taskInfo_T_S',
        'detailInfo'
      ]) // 停止websocket
    }
    if (this.$createWebsocket.pageRWebsocket) {
      this.$createWebsocket.pageRWebsocket('stop', [
        'VolumeChart',
        'slotInfo',
        'receiverData',
        'currentRInfo',
        'setInfo'
      ]) // 停止websocket
    }
  },
  watch: {
    // 更改参数
    tracking: {
      handler (val) {
        const query = {
          timeType: val.timeType,
          peerId: val.peerId,
          sourceType: val.sourceType,
          startTime: typeof val.startTime == 'object' ? (val.startTime && val.startTime.getTime()) || val.startTime : val.startTime || null,
          endTime: typeof val.endTime == 'object' ? (val.endTime && val.endTime.getTime()) || val.endTime : val.endTime || null,
          peerName: val.peerName
        }
        // 多路追踪
        if (val.timeType == '2') {
          query.multiple = val.multiple
        }
        this.$router.push({
          name: 'SourceMapTrack',
          query: query
        })
        setTimeout(() => {
          this.changeCondition(val)
          // 初始化更新设备值,为了防止close事件在筛选后触发
          this.isDeviceWindow = false
          this.deviceTimeFlag = true
          this.allPosition = []
        }, 1000)
      },
      deep: true
    }
  },
  methods: {
    getInterval () {
      this.interval = `${(localStorage.getItem('delayQueryTime') || 10000) / 0.02}_1`
      this.spaceTime = localStorage.getItem('mapStep') || 5000
    },
    async init () {
      this.initGoogleMap()
      this.getHistory()
    },
    initGoogleMap (latitude, longitude) {
      this.loading = false
      this.mapObj = new google.maps.Map(document.getElementById('mapGoogle'), {
        zoom: 11,
        center: new google.maps.LatLng(
          latitude || 31.240008,
          longitude || 121.479048
        ),
        disableDefaultUI: false,
        minZoom: 2,
        maxZoom: 20,
        zoomControl: true
      })
    },
    initMap () {
      Tool.loadMapJs(this.lang, async () => {
        this.loading = false
        this.initGoogleMap()
        let timeParams = {}
        if (this.timer) {
          clearInterval(this.timer)
        }
        if (this.$route.query.timeType != '0') {
          timeParams.starttime = this.params.starttime
          timeParams.endtime = this.params.endtime == 0 ? delete this.params.endtime : this.params.endtime
        } else {
          this.gpsDuration = localStorage.getItem('gpsDuration') || 120
          timeParams.starttime = new Date().getTime() - this.gpsDuration * 60 * 1000
          timeParams.endtime = new Date().getTime()
        }
        if (this.$route.query.timeType === '2') {
          this.handleMultiple()
        } else {
          // 开启webscoket获取当前设备实时信息
          this.createMsgWebsocket()
        }
        if (this.$route.query.timeType != '0') {
          await this.getSourceData(
            timeParams.starttime,
            timeParams.endtime
          )
          await this.getAllMaker()
          Bus.$emit('allMarkerLength', this.allPositionData.length)
          this.getHistory()
          this.historyFlag = true
        } else {
          clearInterval(this.timer)
          this.startNum = 0
          this.makeLine()
          this.firstFlag = 0
          this.timer = setInterval(async () => {
            this.historyFlag = false
            this.makeLine()
          }, 10000)
        }
      })
    },
    initialize () {
      this.map = new google.maps.Map('mapContent')
      this.initCenter()
    },
    async changeCondition (val) {
      //  多路追踪时，查询所有设备的信息
      let peerIds = []
      if (val.timeType == '2') {
        val.multiple.split(',').map(v => {
          peerIds.push(v.split('-')[1])
        })
      }
      // 初始化信息
      this.firstData = []
      this.allMarkData = [] // 所有的mark点
      this.allPositionData = []
      this.currentSource = {} // 当前source的实时信息
      const taskInfoMsg = {
        type: 'taskInfo',
        module: 'taskInfo_T_S',
        data: {
          peerIds: peerIds.length ? peerIds.join(',') : val.peerId
        }
      }
      if (this.pageRequest.sendMsgs.taskInfo_T_S) {
        this.pageRequest.sendMsgs.taskInfo_T_S.message = taskInfoMsg
      }

      this.params.starttime = typeof val.startTime == 'object' ? (val.startTime && val.startTime.getTime()) || val.startTime : val.startTime
      this.params.endtime = typeof val.endTime == 'object' ? (val.endTime && val.endTime.getTime()) || val.endTime : val.endTime

      this.params.peerid = val.peerId
      let timeParams = {}
      if (this.timer) clearInterval(this.timer)
      this.initCenter(val)
      // 历史数据
      if (val.timeType === '2') {
        this.handleMultiple(val.multiple)
      } else {
        // 开启webscoket获取当前设备实时信息
        this.createMsgWebsocket()
        if (this.$createWebsocket.pageRequest) {
          this.$createWebsocket.pageRequest('stop', [
            'getLocationByIps_hm'
          ]) // 停止websocket
        }
      }
      if (val.timeType != '0') {
        timeParams.starttime = this.params.starttime
        timeParams.endtime = this.params.endtime
        await this.getSourceData(
          timeParams.starttime,
          timeParams.endtime,
          val.peerId
        )
        await this.getAllMaker(val)
        Bus.$emit('allMarkerLength', this.allPositionData.length)
        this.historyFlag = true
        this.getHistory()
      } else if (val.timeType === '0') {
        // this.map.clearOverlays();
        this.historyFlag = false
        this.gpsDuration = localStorage.getItem('gpsDuration') || 120
        this.makeLine(val)
        clearInterval(this.timer)
        this.startNum = 0
        this.firstFlag = 0
        this.timer = setInterval(async () => {
          this.makeLine()
        }, 10000)
      }
    },
    getSlotBitrate (peerId, time, callback) {
      // 初始化值
      this.speedInfo = []
      this.slotDetail.map(v => {
        if (v.rttInMsec) v.rttInMsec = ''
        if (v.speed) v.speed = '0'
      })
      this.pageRequest && this.pageRequest.send({
        message: {
          type: 'detailInfo',
          module: 'detailInfo',
          data: { peerIds: peerId || this.$route.query.peerId }
        },
        time: time || 1000,
        key: 'detailInfo',
        isOnce: !!peerId,
        success: res => {
          if (!res.result) return
          const speedInfo = JSON.parse(res.result)[0].Slots
          if (!speedInfo) return
          const result = this.handleSlotAndRtt(speedInfo)
          if (callback) {
            callback(result)
          }
        }
      })
    },
    // 处理slot和rtt
    handleSlotAndRtt (speedInfo) {
      for (let i = 0; i < speedInfo.length; i++) {
        const v = speedInfo[i]
        const rttInMsec = v.rttInMsec || ''
        v.Speed = (typeof v.Speed == 'string' && v.Speed.trim()) || v.Speed
        const qualityVal = (v.Speed && String(Math.round((v.Speed / 1024) * 8))) || '0'
        if (typeof v.SignalType == 'string' && v.CarrierName) {
          this.speedInfo[v.Index - 1] = { qualityVal, rttInMsec }
        } else if (typeof v.SignalType == 'string' && v.SignalType.toLowerCase() == 'hotspot') {
          this.speedInfo[9] = { qualityVal, rttInMsec }
        } else if (typeof v.SignalType == 'string' && v.SignalType.toLowerCase() == 'eth') {
          this.speedInfo[8] = { qualityVal, rttInMsec }
        } else if (typeof v.SignalType == 'string' && v.SignalType.toLowerCase() == 'wifi') {
          this.speedInfo[10] = { qualityVal, rttInMsec }
        }
      }
      for (let i = 0; i < 13; i++) {
        this.speedInfo[i] = this.speedInfo[i] || {
          qualityVal: '0',
          rttInMsec: '',
          speed: ''
        }
        this.slotDetail[i] = this.speedInfo[i] || {
          qualityVal: '0',
          rttInMsec: '',
          speed: ''
        }
        this.slotDetail[i].speed = (this.speedInfo[i] && this.speedInfo[i].qualityVal) || ''
        this.slotDetail[i].rttInMsec = (this.speedInfo[i] && this.speedInfo[i].rttInMsec) || ''
      }
      return JSON.parse(JSON.stringify(this.slotDetail))
    },
    getSourceStatus () {
      // 多路追踪时，查询所有设备的信息
      let peerIds = []
      if (this.$route.query.timeType == '2') {
        this.$route.query.multiple.split(',').map(v => {
          peerIds.push(v.split('-')[1])
        })
      }

      this.pageRequest.send({
        message: {
          type: 'taskInfo',
          module: 'taskInfo_T_S',
          data: {
            peerIds: peerIds.length ? peerIds.join(',') : this.$route.query.peerId
          }
        },
        time: 1000,
        key: 'taskInfo_T_S',
        success: res => {
          if (this.$route.query.timeType == '2') {
            this.getMultipleDeviceInfo(res)
            return
          }
          // 如果当前源不live，停止websocket
          if (res.result[0].status != 2) {
            this.iconType = ''
            if (this.$createWebsocket.pageRWebsocket) {
              this.currentSource.status = res.result[0].status
              this.$createWebsocket.pageRWebsocket('stop', [
                'VolumeChart',
                'slotInfo',
                'receiverData',
                'currentRInfo',
                'setInfo'
              ])
            }
            if (this.$createWebsocket.pageRequest) {
              this.$createWebsocket.pageRequest('stop', [
                'detailInfo'
              ]) // 停止websocket
            }
            return
          }
          // 模式不为多路追踪时，如果当前源之前不在live，现在变为live状态，开启websocket
          if (this.currentSource.status != 2 && res.result[0].status == 2) {
            this.createMsgWebsocket()
          }
          this.currentSource = res.result[0]
          // 将当前的源信息添加进vuex
          this.updateSocketParams('VolumeChart', '2152867840', '200') // 获取音量信息
          this.updateSocketParams('slotInfo', '2152865792', '101') // 获取slot信息
          this.updateSocketParams(
            'receiverData',
            '2152857600',
            '101'
          ) // 获取err and quality信息
          this.updateSocketParams(
            'currentRInfo',
            '2152857600',
            '102'
          ) // 获取type and format信息
          this.updateSocketParams('setInfo', '2152866048', '101') // 获取bitrate and delay信息
        }
      })
    },
    // 获取多路设备的实时信息
    getMultipleDeviceInfo (data) {
      this.allDeviceLivePeerId = []
      data && data.result.map(v => {
        this.allDeviceLivePeerId[v.peerId.toLowerCase()] = v
      })
    },
    // 更新websocket参数
    updateSocketParams (type, categoryId, operationType) {
      const message = {
        categoryId: categoryId,
        operationType: operationType,
        rid: this.currentSource.livePeerId
      }
      if (this.pageRWebsocket.sendMsgs && this.pageRWebsocket.sendMsgs[type]) {
        this.pageRWebsocket.sendMsgs[type].message = message
      }
    },
    createMsgWebsocket (livePeerId, time, peerId) {
      this.sendMsgWebsocket('VolumeChart', '2152867840', '200', time || 200, livePeerId) // 获取音量信息
      this.sendMsgWebsocket('SlotInfo', '2152865792', '101', time || 1000, livePeerId) // 获取slot信息
      this.sendMsgWebsocket('CurrentRInfo', '2152857600', '102', time || 1000, livePeerId) // 获取type and format信息
      this.sendMsgWebsocket('SetInfo', '2152866048', '101', time || 1000, livePeerId) // 获取bitrate and delay信息
      this.sendMsgWebsocket('ReceiverData', '2152857600', '101', time || 1000, livePeerId) // 获取err and quality信息
      this.getSlotBitrate(peerId, time)
    },
    sendMsgWebsocket (type, categoryId, operationType, time, livePeerId, callback) {
      let typeVal = ''
      if (type != 'VolumeChart') {
        typeVal = `${type[0].toLowerCase()}${type.slice(1, type.length)}`
      }
      this.pageRWebsocket.send({
        message: {
          categoryId: categoryId,
          operationType: operationType,
          rid: livePeerId || this.currentSource.livePeerId
        },
        time: time || 5000,
        key: typeVal || type,
        isOnce: !!livePeerId,
        success: res => {
          this.updateSourceData(type, JSON.parse(res.data))
          callback && callback()
          if (res.data && type === 'SlotInfo') {
            const slotData = JSON.parse(res.data).AdapterModels
            this.handleSlotInfo(slotData)
          }
          this.getSignalStrength(res, type)
        }
      })
    },
    // 获取信号强度
    getSignalStrength (res, type) {
      if (res.data && type === 'SetInfo') {
        if (this.sourceDataObj.receiverdata.QualityMeasureOnGUI === undefined) return
        this.bitRate = (this.sourceDataObj.receiverdata.QualityMeasureOnGUI / JSON.parse(res.data).CurrentSetting.Bitrate) * 100
        let flag = false
        if (this.bitRate > 40 && this.bitRate < 60) {
          flag = this.singnalArr.includes('red')
          this.iconType = flag ? 'red' : 'orange'
        }
        this.singnalArr.push(flag || this.iconType == 'orange' ? 'orange' : '')
        if (this.singnalArr.length >= 10) {
          this.singnalArr.shift()
        }
      } else if (res.data && type === 'ReceiverData') {
        // 8s
        const eightSeconds = this.sourceDataObj.receiverdata.ErrorRateLastNSec
        // 1s
        const oneSeconds = this.sourceDataObj.receiverdata.ErrorRateLastSec
        if (eightSeconds > 0 && oneSeconds > 0) {
          this.iconType = 'red'
        }
        if (eightSeconds == 0 && oneSeconds == 0 && this.bitRate >= 60) {
          this.iconType = ''
        }
        this.singnalArr.push(this.iconType)
        if (this.singnalArr.length >= 10) {
          this.singnalArr.shift()
        }
      }
    },
    // 处理slot信息
    handleSlotInfo (data) {
      if (data && data.length) {
        this.slotDetail = [[], [], [], [], [], []]
        for (let i = 0; i < data.length; i++) {
          const v = data[i]
          if (typeof v.SignalType == 'string') {
            v.CarrierName = v.CarrierName || (this.currentSource.platform >= 10 && this.currentSource.platform <= 14 && v.SignalType) || ''
            this.slotDetail[v.Index - 1] = {
              carrierName: v.CarrierName || '',
              speed: (this.speedInfo[v.Index - 1] && this.speedInfo[v.Index - 1].qualityVal) || '0',
              rttInMsec: (this.speedInfo[v.Index - 1] && this.speedInfo[v.Index - 1].rttInMsec) || ''
            }
          }
        }
        for (let i = 0; i < 6; i++) {
          this.slotDetail[i] = this.slotDetail[i] || {
            carrierName: '',
            speed: '0'
          }
        }
      }
    },
    updateSourceData (type, data) {
      this.sourceDataObj[type.toLowerCase()] = data
    },
    // 获取delay
    getDelay (value, setFlag) {
      if (!setFlag) {
        const val = this.$t('lang.Dynamic')
        return val
      }
      return `${value} s`
    },
    // 从数据库获取源的历史数据
    async getSourceData (startTime, endTime, peerId, clickOpenDetail) {
      if (!startTime) return
      const params = {
        peerId: peerId || this.$route.query.peerId,
        startTime: String(startTime),
        endTime: String(endTime || startTime)
      }
      let data = {}
      let url = '/ccp/tvucc-device/geoRecord/listGeoRecord'
      const collect = sessionStorage.getItem('collect')
      if (collect && Tool.isJSON(collect) && JSON.parse(collect).isSelect && this.$route.query.timeType != '0') {
        url = '/ccp/tvucc-device/favorite/listGeoRecordFavorite'
      }
      if (clickOpenDetail) {
        url = '/ccp/tvucc-device/geoRecord/listGeoRecord'
      }
      await this.axios.post(url, params, {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        }
      }).then(res => {
        if (res.data.errorCode == '0x0' && res.data.result) {
          data = endTime ? res.data.result : res.data.result[0]
          if (endTime) this.allMarkData = res.data.result
        }
      }).catch(() => {})
      return data
    },
    getThumbnail (item, timestamp) {
      let _url = ''
      const blobUrl = this.$store.state.common.baseUrl.blobUrl
      if (!item) return _url
      const status = item.status
      const header = window.location.protocol.includes('https') ? 'https://' : 'http://'
      const isCompressThumbnail = JSON.parse(localStorage.getItem('newCCInitConfig')).urlInfo.isCompressThumbnail
      if (status === '2' || status == '1') {
        _url = `${header}${blobUrl}/${item.peerId && item.peerId.toUpperCase()}/${timestamp}?`
        _url = isCompressThumbnail == '0' ? `${_url}type=original&app=cc&${Math.random()}&tl=60` : `${_url}${Math.random()}&app=cc&tl=60`
      }
      return _url
    },
    // 存储数据到数据库
    async saveSourceData (mark) {
      let signalStrengthVal = ''
      if (this.iconType == 'red') {
        signalStrengthVal = '2'
      } else if (this.iconType == 'orange') {
        signalStrengthVal = '3'
      }
      let slotinfo = []
      this.sourceDataObj.slotinfo &&
        this.sourceDataObj.slotinfo.AdapterModels.map(v => {
          slotinfo.push(v.Status)
        })
      let QualityMeasureOnGUI = this.sourceDataObj.receiverdata.QualityMeasureOnGUI
      if (QualityMeasureOnGUI === 0) {
        QualityMeasureOnGUI = String(QualityMeasureOnGUI)
      }
      const sourceData = {
        peerId: this.$route.query.peerId,
        bitrate: (this.sourceDataObj.setinfo.CurrentSetting.Bitrate && String(this.sourceDataObj.setinfo.CurrentSetting.Bitrate)) || '',
        longitude: mark[1],
        latitude: mark[0],
        realTimeParams: JSON.stringify({
          QualityMeasureOnGUI: QualityMeasureOnGUI || '',
          type: this.sourceDataObj.currentrinfo.CurrentTInputType.ReadableName,
          format: this.sourceDataObj.currentrinfo.CurrentTFormat.ReadableName,
          delay: this.getDelay(
            (this.sourceDataObj.setinfo.CurrentSetting.Delay / 1000).toFixed(1),
            this.sourceDataObj.setinfo.CurrentTCanSetDelay
          ),
          error: this.sourceDataObj.receiverdata.ErrorRateLastSec,
          quality: this.sourceDataObj.receiverdata.ReSendRateLastSec,
          slotInfo: slotinfo,
          img: this.getThumbnail(
            this.currentSource,
            Math.round(mark[3] / 1000)
          ),
          voiceInfo: this.sourceDataObj.volumechart,
          signalStrength: signalStrengthVal,
          status: this.currentSource.status,
          slotDetail: this.slotDetail
        }),
        timestamp: mark[3]
      }
      if (!sourceData.peerId || !sourceData.longitude || !sourceData.latitude || !sourceData.timestamp) return
      await this.axios.post('/ccp/tvucc-device/geoRecord/saveGeoRecord', sourceData, {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        }
      }).then(res => {
        if (res.data.errorCode == '0x0') {
          let markType = 'add'
          for (let val of this.allMarkData) {
            if (val.timestamp == sourceData.timestamp) {
              val = sourceData
              markType = 'edit'
              break
            }
          }
          // 如果找不到数据就添加
          if (markType == 'add') {
            this.allMarkData.push(sourceData)
          }
          return true
        }
        return false
      }).catch(() => {
        return false
      })
    },
    // 保存手动打点
    async savePoint (mark, formData, pos) {
      let slotinfo = []
      this.sourceDataObj.slotinfo &&
        this.sourceDataObj.slotinfo.AdapterModels.map(v => {
          slotinfo.push(v.Status)
        })
      let QualityMeasureOnGUI = this.sourceDataObj.receiverdata.QualityMeasureOnGUI
      if (QualityMeasureOnGUI === 0) {
        QualityMeasureOnGUI = String(QualityMeasureOnGUI)
      }
      const sourceData = {
        peerId: this.$route.query.peerId,
        bitrate: (mark.bitrate != undefined && String(mark.bitrate)) || (this.sourceDataObj.setinfo.CurrentSetting.Bitrate && String(this.sourceDataObj.setinfo.CurrentSetting.Bitrate)) || '',
        longitude: (mark.longitude != undefined && String(mark.longitude)) || pos.longitude.toString() || '',
        latitude: (mark.latitude != undefined && String(mark.latitude)) || pos.latitude.toString() || '',
        realTimeParams: JSON.stringify({
          QualityMeasureOnGUI: QualityMeasureOnGUI || '',
          type: this.sourceDataObj.currentrinfo.CurrentTInputType.ReadableName,
          format: this.sourceDataObj.currentrinfo.CurrentTFormat.ReadableName,
          delay: this.getDelay(
            (this.sourceDataObj.setinfo.CurrentSetting.Delay / 1000).toFixed(1),
            this.sourceDataObj.setinfo.CurrentTCanSetDelay
          ),
          error: this.sourceDataObj.receiverdata.ErrorRateLastSec,
          quality: this.sourceDataObj.receiverdata.ReSendRateLastSec,
          slotInfo: slotinfo,
          img: this.getThumbnail(
            this.currentSource,
            Math.round(mark.timestamp / 1000)
          ),
          signalStrength: Object.prototype.hasOwnProperty.call(mark, 'realTimeParams') && JSON.parse(mark.realTimeParams).signalStrength,
          voiceInfo: this.sourceDataObj.volumechart,
          status: this.currentSource.status
        }),
        signalStrength: (formData.signalStrength && formData.signalStrength.trim()) || '',
        address: formData.address.trim() || '',
        remark: formData.remark.trim() || '',
        timestamp: (mark.timestamp != undefined && String(mark.timestamp)) || this.saveTime,
        slotDetail: this.slotDetail
      }
      if (!sourceData.peerId || !sourceData.longitude || !sourceData.latitude || !sourceData.timestamp) return
      if (sourceData.realTimeParams && sourceData.realTimeParams.length >= 512) return
      await this.axios.post('/ccp/tvucc-device/geoRecord/saveGeoRecord', sourceData, {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        }
      }).then(async res => {
        if (res.data.errorCode == '0x0') {
          // 更新本地的allMarkdata
          let markType = 'add'
          for (let val of this.allMarkData) {
            if (val.timestamp == Number(sourceData.timestamp)) {
              val = sourceData
              markType = 'edit'
              break
            }
          }
          // 如果找不到数据就添加
          if (markType == 'add') {
            this.allMarkData.push(sourceData)
          }
          // 保存后设置地图标志颜色
          if (mark && Object.prototype.hasOwnProperty.call(formData, 'signalStrength')) {
            let point
            if (mark.longitude && mark.latitude) {
              point = new google.maps.LatLng(
                mark.latitude,
                mark.longitude
              )
            } else {
              point = new google.maps.LatLng(
                pos.latitude.toString(),
                pos.longitude.toString()
              )
            }
            // 选择Good
            if (formData.signalStrength === '0') {
              if (this.manualGreen.length === 0) {
                point.timestamp = mark.timestamp
                this.manualGreen.push(point)
              } else {
                let flag = false
                for (let i = 0; i < this.manualGreen.length; i++) {
                  if (this.manualGreen[i].timestamp == mark.timestamp) {
                    flag = true
                  }
                }
                if (!flag) {
                  point.timestamp = mark.timestamp
                  this.manualGreen.push(point)
                }
              }
              this.savePointRender('location_green', point)
            } else if (formData.signalStrength === '1') {
              if (this.manualOrange.length === 0) {
                point.timestamp = mark.timestamp
                this.manualOrange.push(point)
              } else {
                let flag = false
                for (let i = 0; i < this.manualOrange.length; i++) {
                  if (this.manualOrange[i].timestamp == mark.timestamp) flag = true
                }
                if (!flag) {
                  point.timestamp = mark.timestamp
                  this.manualOrange.push(point)
                }
              }
              this.savePointRender('location_yellow', point)
            } else if (this.singnalString == 'very bad') {
              this.savePointRender('location_red', point)
            } else if (this.singnalString == 'worse') {
              this.savePointRender('location_orange', point)
            }
            $('.gm-style-iw-t').hide()
          }
          return true
        }
        return false
      }).catch(() => {
        return false
      })
      await this.getAllMaker()
      if (this.historyFlag) {
        this.getHistory()
      } else {
        this.makeLine()
      }
      if (Object.keys(mark).length !== 0) {
        const centerPoint = new google.maps.LatLng(mark.latitude, mark.longitude)
        this.mapObj.setCenter(centerPoint)
      } else {
        const centerPoint = new google.maps.LatLng(pos.latitude, pos.longitude)
        this.mapObj.setCenter(centerPoint)
      }
    },
    savePointRender (val, point) {
      const that = this
      const markers = new google.maps.Marker({
        position: point,
        icon: require('@/assets/img/map/' + val + '.svg')
      })
      this.markersArray.push(markers)
      markers.setMap(this.mapObj)
      markers.addListener('click', async function (e) {
        const position = {
          y: e.latLng.lng(),
          x: e.latLng.lat()
        }
        let timestamp = null
        if (that.allMarkData.length !== 0) {
          timestamp = that.getTimestamp(
            position,
            that.allMarkData,
            true
          )
          if (!timestamp) {
            timestamp = that.getTimestamp(
              position,
              that.allPositionData
            )
          }
        } else {
          timestamp = that.getTimestamp(
            position,
            that.allPositionData
          )
        }
        const strInfo = await that.openSourceDetail(timestamp, position)
        const infoModal = new google.maps.InfoWindow({
          content: strInfo
        })
        infoModal.open(this.mapObj, markers)
        setTimeout(() => {
          // 系统自动打点设置删除按钮不显示
          that.hideDelete()
        }, 0)
      })
    },
    // 处理多路追踪
    handleMultiple (multiple) {
      const devices = multiple || this.$route.query.multiple
      let peerIds = []
      devices && devices.split(',').map(v => { peerIds.push(v.split('-')[1]) })
      if (this.$createWebsocket.pageRequest) {
        this.$createWebsocket.pageRequest('stop', [
          'getLocationByIps_hm'
        ]) // 停止websocket
      }
      // 获取多个设备的实时位置
      this.getDevicePosition(peerIds)
      this.stopDeviceWebsocket()
    },
    // stop websocket
    stopDeviceWebsocket () {
      if (this.$createWebsocket.pageRWebsocket) {
        this.$createWebsocket.pageRWebsocket('stop', [
          'VolumeChart',
          'slotInfo',
          'receiverData',
          'currentRInfo',
          'setInfo'
        ])
      }
      if (this.$createWebsocket.pageRequest) {
        this.$createWebsocket.pageRequest('stop', ['detailInfo']) // 停止websocket
      }
    },
    // 获取多个设备的实时位置
    getDevicePosition (peerIds) {
      this.pageRequest.send({
        message: {
          type: 'getLocationByIps',
          module: 'getLocationByIps_hm',
          data: {
            peerIds: peerIds.join(','),
            peerIps: '',
            mapType: this.mapType
          }
        },
        time: 1000,
        key: 'getLocationByIps_hm',
        success: res => {
          if (!res.result || !res.result.errorCode != '0x0') return
          const positionArr = JSON.parse(res.result.result).data
          this.setDevicePosition(positionArr)
        }
      })
    },
    getlabelContent (name) {
      const labelContent = `<div title="${name}" class='device_icon_name'><div class="ellipsis">${name}</div></div>`
      return labelContent
    },
    // 设置设备位置
    setDevicePosition (positionArr) {
      if (this.isDeviceWindow) return
      let deviceObj = {}
      positionArr.map(v => { deviceObj[v.peerId] = v.location })
      // 清除上次的位置
      let noRenderDevice = []
      this.allPosition.map(v => {
        if (deviceObj[v.deviceData.peerId] != v.deviceData.location) {
          v.marker.setMap(null)
        } else {
          noRenderDevice.push(v.deviceData.peerId.toLowerCase())
        }
      })
      let lastAllPosition = {}
      this.allPosition.map(v => {
        lastAllPosition[v.deviceData.peerId.toLowerCase()] = v
      })
      this.allPosition = []
      const icons = {
        device: {
          icon: require('@/assets/img/map/multi-tracking.svg')
        }
      }
      // 重新设置位置
      for (let i = 0; i < positionArr.length; i++) {
        const v = positionArr[i]
        if (noRenderDevice.includes(v.peerId.toLowerCase())) {
          this.allPosition.push(
            lastAllPosition[v.peerId.toLowerCase()]
          )
          continue
        }
        const name = this.allDeviceLivePeerId[v.peerId.toLowerCase()].name
        const pt = new google.maps.LatLng(
          Number(v.location.split(',')[0]),
          Number(v.location.split(',')[1])
        )
        const labelContent = this.getlabelContent(name)
        const marker = new MarkerWithLabel({
          position: pt,
          icon: icons.device.icon,
          map: this.mapObj,
          title: name,
          labelContent: labelContent,
          labelAnchor: new google.maps.Point(58, 0),
          labelClass: 'labelC'
        })
        marker.setTitle(`${this.$t('lang.equipmentIdentity')}${v.peerId.toLowerCase()}\n${this.$t('lang.equipmentName')}${name}`)
        this.allPosition.push({
          marker,
          deviceData: JSON.parse(JSON.stringify(v))
        })
        // 点击
        this.clickDeviceMarker(marker, v)
      }
    },
    // 单次查询设备信息
    singleQueryInfo (key, categoryId, operationType, time, livePeerId) {
      return new Promise((resolve, reject) => {
        this.sendMsgWebsocket(
          key,
          categoryId,
          operationType,
          time,
          livePeerId,
          resolve
        ) // 获取slot信息
      })
    },
    // 点击多路追踪中的设备
    clickDeviceMarker (marker, item) {
      const that = this
      marker.addListener('click', async function (e) {
        if (!that.deviceTimeFlag) return
        that.deviceTimeFlag = false
        that.loading = true
        // 多路设备模式下，查询点击设备的信息
        const livePeerId = that.allDeviceLivePeerId[item.peerId.toLowerCase()].livePeerId
        item.name = that.allDeviceLivePeerId[item.peerId.toLowerCase()].name
        const source = {
          status: 1,
          type: 't'
        }
        const sourceData = {
          deviceName: item.name,
          peerId: '',
          bitrate: '',
          longitude: parseInt(item.location.split(',')[1] * 100000000) / 100000000,
          latitude: parseInt(item.location.split(',')[0] * 100000000) / 100000000,
          realTimeParams: JSON.stringify({
            QualityMeasureOnGUI: '',
            type: '',
            format: '',
            delay: '',
            error: '',
            quality: '',
            slotInfo: {},
            img: '',
            voiceInfo: {},
            signalStrength: '',
            status: '1',
            slotDetail: [[], [], [], [], [], []]
          }),
          timestamp: ''
        }
        if (livePeerId) {
          source.status = 2
          that.isDeviceWindow = true
          let promiseArr = []
          promiseArr.push(
            new Promise((resolve, reject) => {
              // 从T上拿slot信息
              that.getSlotBitrate(item.peerId, 1, resolve)
            })
          )
          promiseArr.push(
            that.singleQueryInfo(
              'VolumeChart',
              '2152867840',
              '200',
              1,
              livePeerId
            )
          ) // 获取音量信息
          promiseArr.push(
            that.singleQueryInfo(
              'SlotInfo',
              '2152865792',
              '101',
              1,
              livePeerId
            )
          ) // 获取slot信息
          promiseArr.push(
            that.singleQueryInfo(
              'CurrentRInfo',
              '2152857600',
              '102',
              1,
              livePeerId
            )
          ) // 获取type and format信息
          promiseArr.push(
            that.singleQueryInfo(
              'SetInfo',
              '2152866048',
              '101',
              1,
              livePeerId
            )
          ) // 获取bitrate and delay信息
          promiseArr.push(
            that.singleQueryInfo(
              'ReceiverData',
              '2152857600',
              '101',
              1,
              livePeerId
            )
          ) // 获取err and quality信息
          let isOpen = false // 是否打开弹窗
          Promise.all(promiseArr).then(values => {
            that.handleWindowInfo(
              item,
              this,
              source,
              sourceData,
              marker
            )
            that.loading = false
            isOpen = true
            that.deviceTimeFlag = true
          }).catch(() => {
            that.handleWindowInfo(
              item,
              this,
              source,
              sourceData,
              marker
            )
            that.loading = false
            isOpen = true
            that.deviceTimeFlag = true
          })
          setTimeout(() => {
            that.loading = false
            that.isDeviceWindow = isOpen
            that.deviceTimeFlag = true
          }, 3000)
        } else {
          setTimeout(() => {
            that.deviceTimeFlag = true
          }, 1500)
          setTimeout(() => {
            that.isDeviceWindow = true
          })
          that.isDeviceWindow = true
          // let strInfo = await that.openSourceDetail("", {}, sourceData);
          const strInfo = await that.openSourceDetail(
            new Date(),
            {},
            sourceData
          )
          const infowindow = new google.maps.InfoWindow({
            content: strInfo
          })
          infowindow.open(this.mapObj, marker)
          that.loading = false
        }
      })
    },
    // 弹窗中的消息从websocket中获取
    async handleWindowInfo (item, that, source, sourceData, marker) {
      let QualityMeasureOnGUI = this.sourceDataObj.receiverdata.QualityMeasureOnGUI
      if (QualityMeasureOnGUI === 0) {
        QualityMeasureOnGUI = String(QualityMeasureOnGUI)
      }
      let slotinfo = []
      this.sourceDataObj.slotinfo &&
        this.sourceDataObj.slotinfo.AdapterModels.map(v => {
          slotinfo.push(v.Status)
        })
      sourceData = {
        deviceName: item.name,
        peerId: item.peerId,
        bitrate: (this.sourceDataObj.setinfo.CurrentSetting.Bitrate && String(this.sourceDataObj.setinfo.CurrentSetting.Bitrate)) || '',
        longitude: parseInt(item.location.split(',')[1] * 100000000) / 100000000,
        latitude: parseInt(item.location.split(',')[0] * 100000000) / 100000000,
        realTimeParams: JSON.stringify({
          QualityMeasureOnGUI: QualityMeasureOnGUI || '',
          type: this.sourceDataObj.currentrinfo.CurrentTInputType.ReadableName,
          format: this.sourceDataObj.currentrinfo.CurrentTFormat.ReadableName,
          delay: this.getDelay((this.sourceDataObj.setinfo.CurrentSetting.Delay / 1000).toFixed(1), this.sourceDataObj.setinfo.CurrentTCanSetDelay),
          error: this.sourceDataObj.receiverdata.ErrorRateLastSec,
          quality: this.sourceDataObj.receiverdata.ReSendRateLastSec,
          slotInfo: slotinfo,
          img: this.getThumbnail(source),
          voiceInfo: this.sourceDataObj.volumechart,
          signalStrength: '',
          status: '2',
          slotDetail: this.slotDetail
        }),
        timestamp: ''
      }
      const strInfo = await this.openSourceDetail('', {}, sourceData)
      const infowindow = new google.maps.InfoWindow({
        content: strInfo
      })
      infowindow.open(this.mapObj, marker)
      setTimeout(() => {
        this.isDeviceWindow = true
      })
    },
    // 存储所有maker点
    async getAllMaker (val) {
      if (val) {
        if (val.timeType === '0') {
          this.params.starttime = this.interval;
          (this.params.endtime || this.params.endtime === 0) &&
            delete this.params.endtime
        }
      } else {
        if (this.$route.query.timeType === '0') {
          this.params.starttime = this.interval;
          (this.params.endtime || this.params.endtime === 0) && delete this.params.endtime
        }
      }
      await this.axios.get(`${this.url}/Out/getGPSTrackingServlet`, { params: this.params }, {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        }
      }).then(async res => {
        if (!res.data || (typeof res.data == 'string' && !res.data.trim())) return
        if (this.$route.query.timeType === '0') {
          // 如果返回了手动设置的点，比较返回时间与当前时间是否相差超过一定范围
          const incrementalRangeTime = localStorage.getItem('incrementalRangeTime')
          const manualIncrementTime = localStorage.getItem('manualIncrementTime')
          const currentGps = res.data.trim().split('\r\n')
          const currentTime = new Date().getTime()
          let removeGps = []
          currentGps.map((v, i) => {
            const currentData = v.split(',')
            // 返回时间与当前时间相差超过一定范围
            if (Math.abs(currentTime - currentData[3]) > incrementalRangeTime) {
              // 当前时间-计算后时间的差值是否在一定范围内，如果不在，则去掉这个点
              if (Math.abs(currentTime - Math.abs(currentData[3] - manualIncrementTime)) > incrementalRangeTime) {
                removeGps.push(i)
              } else {
                // 在则更改这个点的时间戳
                currentData[3] = currentData[3] - manualIncrementTime
              }
            }
          })
          let removeNum = 0
          removeGps.map(v => {
            currentGps.splice(v - removeNum, 1)
            removeNum++
          })
          res.data = currentGps.join('\r\n')
        }
        this.firstData = res.data.trim().split('\r\n')
        // 间隔时间为10秒以上的才显示点
        let gpsPoints = [] // gps返回的点
        let filterGpsArr = []
        this.firstData.map(v => {
          if (!v) return
          const gpsPoint = v.split(',')
          gpsPoints.push({
            longitude: gpsPoint[1],
            latitude: gpsPoint[0],
            timestamp: gpsPoint[3]
          })
          if (!filterGpsArr.length) {
            filterGpsArr.push(v)
          }
          if (v.split(',')[3] - filterGpsArr[filterGpsArr.length - 1].split(',')[3] >= this.spaceTime) {
            filterGpsArr.push(v)
          }
        })
        res.data = filterGpsArr.join('\r\n')

        // 合并geo坐标和标记坐标，以标记坐标为基础，两个点之间的时间戳
        let filterMarkPoints = []
        let filterGeoPoints = []
        let secondFilterGeoPoints = []
        let timestampArr = []
        this.allMarkData.sort((m, n) => {
          return m.timestamp - n.timestamp
        })
        gpsPoints.sort((m, n) => {
          return m.timestamp - n.timestamp
        })
        // 筛选marker点间隔一定时间
        this.allMarkData.map(v => {
          if (!filterMarkPoints.length) {
            timestampArr.push(String(v.timestamp))
            filterMarkPoints.push(v)
          }
          if (v.timestamp - filterMarkPoints[filterMarkPoints.length - 1].timestamp >= this.spaceTime) {
            timestampArr.push(String(v.timestamp))
            filterMarkPoints.push(v)
          }
        })
        filterMarkPoints.map((v, i) => {
          gpsPoints.map(item => {
            if (!filterGeoPoints.length && !timestampArr.includes(String(v.timestamp))) {
              timestampArr.push(String(item.timestamp))
              filterGeoPoints.push(item)
            }
            // 当前geo点与当前marker点的时间戳差值
            const b = Math.abs(item.timestamp - v.timestamp)
            // 当前geo点与下一个marker点的时间戳差值
            const c = (filterMarkPoints[i + 1] && filterMarkPoints[i + 1].timestamp - item.timestamp) || false
            if ((b >= this.spaceTime && c !== false && c >= this.spaceTime) || (c === false && b >= this.spaceTime)) {
              if (!timestampArr.includes(String(item.timestamp))) {
                filterGeoPoints.push(item)
                timestampArr.push(String(item.timestamp))
              }
            }
          })
        })
        filterGeoPoints.map(v => {
          if (!secondFilterGeoPoints.length) {
            secondFilterGeoPoints.push(v)
          }
          if (v.timestamp - secondFilterGeoPoints[secondFilterGeoPoints.length - 1].timestamp >= this.spaceTime) {
            secondFilterGeoPoints.push(v)
          }
        })
        const concatArr = filterGeoPoints.concat(filterMarkPoints).sort((m, n) => {
          return m.timestamp - n.timestamp
        })
        this.allPositionData = []
        concatArr.map(v => {
          if (!this.allPositionData.length) {
            this.allPositionData.push(v)
            return
          }
          const lastPoint = this.allPositionData[
            this.allPositionData.length - 1
          ]
          if (v.timestamp - lastPoint.timestamp >= this.spaceTime || v.id) {
            this.allPositionData.push(v)
          }
        })
        // mark点为空时
        if (!filterMarkPoints.length && gpsPoints.length) {
          let gpsPointsFilter = []
          // gpsPoints.map(v => {
          for (let i = 0; i < gpsPoints.length; i++) {
            const v = gpsPoints[i]
            if (!gpsPointsFilter.length) {
              gpsPointsFilter.push(v)
              continue
            }
            if (!v.timestamp || !gpsPointsFilter[gpsPointsFilter.length - 1].timestamp) continue
            if (v.timestamp - gpsPointsFilter[gpsPointsFilter.length - 1].timestamp >= this.spaceTime) {
              gpsPointsFilter.push(v)
            }
          }
          this.allPositionData = gpsPointsFilter
        }
        const infos = JSON.parse(JSON.stringify(this.firstData))
        let points = []
        this.timestamps = []
        for (const i in infos) {
          const ainfo = infos[i].split(',')
          points.push(
            new google.maps.LatLng(
              Number(ainfo[0]),
              Number(ainfo[1])
            )
          )
          this.timestamps[i] = ainfo[3] + '_' + 0
        }

        if (this.currentSource.status == 2 && this.$route.query.timeType == '0') {
          // 如果源不在线了，停止保存
          if (res.data && infos.length) {
            await this.saveSourceData(
              infos[infos.length - 1].split(',')
            )
          }
        }
      }).catch(() => {})
    },
    // 更新历史数据
    getHistory (maker) {
      if (this.markersArray.length > 0 && this.deleteStatus) {
        for (const i in this.markersArray) {
          this.markersArray[i].setMap(null)
        }
        this.markersArray.length = 0
        this.deleteStatus = false
      }
      let flightPlanCoordinates = []
      for (let i = 0; i < this.allPositionData.length; i++) {
        const makerPoint = this.allPositionData[i]
        flightPlanCoordinates.push({
          lng: Number(makerPoint.longitude),
          lat: Number(makerPoint.latitude)
        })
      }
      const flightPath = new google.maps.Polyline({
        path: flightPlanCoordinates,
        strokeColor: '#18A45B',
        strokeOpacity: 1.0,
        strokeWeight: 4
      })
      flightPath.setMap(this.mapObj)
      flightPath.addListener('click', async e => {
        this.clickPolyline(e)
      })
      if (flightPlanCoordinates.length > 0) {
        const centerPoint = flightPlanCoordinates[flightPlanCoordinates.length - 1]

        this.mapObj.setCenter(
          new google.maps.LatLng(centerPoint.lat, centerPoint.lng)
        )
        var bounds = new google.maps.LatLngBounds()
        for (var i = 0; i < flightPlanCoordinates.length; i++) {
          bounds.extend(flightPlanCoordinates[i])
        }
        this.mapObj.fitBounds(bounds)
      }

      for (let i = 0; i < flightPlanCoordinates.length; i++) {
        const makerPoint = this.allPositionData[i]
        // 手动打点绿色
        if (makerPoint.signalStrength == '0') {
          this.pointRender(i, flightPlanCoordinates[i], 'location_green')
        } else if (makerPoint.signalStrength == '1') {
          this.pointRender(i, flightPlanCoordinates[i], 'location_yellow')
        } else if (Object.prototype.hasOwnProperty.call(makerPoint, 'realTimeParams') && JSON.parse(makerPoint.realTimeParams).signalStrength === '2') {
          this.pointRender(i, flightPlanCoordinates[i], 'location_red')
        } else if (Object.prototype.hasOwnProperty.call(makerPoint, 'realTimeParams') && JSON.parse(makerPoint.realTimeParams).signalStrength === '3') {
          this.pointRender(i, flightPlanCoordinates[i], 'location_orange')
        } else {
          this.pointRender(i, flightPlanCoordinates[i], 'map_point')
        }
        if (this.manualGreen.length != 0) {
          this.manualGreen.forEach((item, index) => {
            this.pointRender('', item, 'location_green')
          })
        }

        if (this.manualOrange.length != 0) {
          this.manualOrange.forEach((item, index) => {
            this.pointRender('', item, 'location_yellow')
          })
        }
        if (maker) {
          const centerPoint = new google.maps.LatLng(
            makerPoint.latitude,
            makerPoint.longitude
          )
          this.mapObj.setCenter(centerPoint)
        } else {
          this.mapObj.setCenter(
            flightPlanCoordinates[flightPlanCoordinates.length - 1]
          )
        }
      }
    },
    pointRender (i, flightPlanCoordinates, val) {
      let markers = new google.maps.Marker({
        position: flightPlanCoordinates,
        icon: require('@/assets/img/map/' + val + '.svg')
      })
      this.markersArray.push(markers)
      markers.setMap(this.mapObj)
      if (typeof i == 'number') {
        if (i == this.allPositionData.length - 1) {
          let icon = null
          icon = require('@/assets/img/map/end_point.svg')

          markers = new google.maps.Marker({
            position: flightPlanCoordinates,
            icon: icon
          })
          markers.setMap(this.mapObj)
        }
        if (i === 0) {
          markers = new google.maps.Marker({
            position: flightPlanCoordinates,
            icon: require('@/assets/img/map/start_point.svg')
          })
          markers.setMap(this.mapObj)
        }
      }

      let that = this
      markers.addListener('click', async function (e) {
        let position = {
          y: e.latLng.lng(),
          x: e.latLng.lat()
        }
        let timestamp = null
        if (that.allMarkData.length !== 0) {
          timestamp = that.getTimestamp(
            position,
            that.allMarkData,
            true
          )
          if (!timestamp) {
            timestamp = that.getTimestamp(
              position,
              that.allPositionData
            )
          }
        } else {
          timestamp = that.getTimestamp(
            position,
            that.allPositionData
          )
        }
        let strInfo = await that.openSourceDetail(timestamp, position)

        let infowindow = new google.maps.InfoWindow({
          content: strInfo
        })
        infowindow.open(this.mapObj, markers)
        setTimeout(() => {
          // 系统自动打点设置删除按钮不显示
          that.hideDelete()
        }, 10)
      })
    },
    // 比特率的颜色
    showBitClass (sourceData, realTimeParams) {
      let _str = ''
      const realBit = realTimeParams.QualityMeasureOnGUI
      const setBit = sourceData.bitrate
      if (realBit / setBit < 0.6) {
        _str = 'orange'
      }
      if (realBit / setBit < 0.4) {
        _str = 'flashRed'
      }
      return _str
    },
    async openSourceDetail (time, position, sourceData) {
      $('.gm-style-iw-t').hide()
      let clickOpenDetail = false
      if (sourceData === undefined) {
        clickOpenDetail = true
        this.gpsDuration = localStorage.getItem('gpsDuration') || 120
        sourceData = (time && (await this.getSourceData(
          time,
          undefined,
          undefined,
          clickOpenDetail
        ))) || {}
      }
      this.windowDetail = sourceData
      this.saveTime = time
      let makerPos = {}
      // 对没有信息的点的坐标进行处理
      if (Object.keys(sourceData).length == 0) {
        makerPos.longitude = position.y
        makerPos.latitude = position.x
      } else {
        makerPos.longitude = Number(sourceData.longitude)
        makerPos.latitude = Number(sourceData.latitude)
        this.signal = sourceData.signalStrength
      }
      this.mapSourceData = sourceData
      // 首先处理接口回显form表单的数据
      let addressValue = !this.mapSourceData.address ? '' : this.mapSourceData.address
      let remark = !this.mapSourceData.remark ? '' : this.mapSourceData.remark
      let signalStrength = !this.mapSourceData.signalStrength ? '' : this.mapSourceData.signalStrength
      // 信号显示
      let singnalValue = ''
      let singnalFormatter = ''
      if (this.mapSourceData.signalStrength && this.mapSourceData.signalStrength !== '') {
        singnalValue = this.mapSourceData.signalStrength === '1' ? 'Bad' : 'Good'
        singnalFormatter = this.mapSourceData.signalStrength === '1' ? this.$t('lang.bad') : this.$t('lang.good')
      } else if (Object.prototype.hasOwnProperty.call(this.mapSourceData, 'realTimeParams') && JSON.parse(this.mapSourceData.realTimeParams).signalStrength === '2') {
        singnalValue = 'very bad'
        singnalFormatter = this.$t('lang.veryBad')
      } else if (Object.prototype.hasOwnProperty.call(this.mapSourceData, 'realTimeParams') && JSON.parse(this.mapSourceData.realTimeParams).signalStrength === '3') {
        singnalValue = 'worse'
        singnalFormatter = this.$t('lang.worse')
      } else {
        singnalValue = 'Good'
        singnalFormatter = this.$t('lang.good')
      }
      this.singnalString = singnalValue

      let realTimeParams = (sourceData.realTimeParams && JSON.parse(sourceData.realTimeParams)) || {}
      // 计算比特率总和
      let totalBit = 0
      realTimeParams.slotDetail = realTimeParams.slotDetail || [
        [],
        [],
        [],
        [],
        [],
        []
      ]
      realTimeParams.slotDetail.length &&
        realTimeParams.slotDetail.map(v => {
          totalBit += Number(v.speed || 0)
        })
      let realBit
      if (Object.prototype.hasOwnProperty.call(sourceData, 'bitrate')) {
        sourceData.bitrate = sourceData.bitrate || 0
        realBit = totalBit <= sourceData.bitrate ? totalBit : sourceData.bitrate
        // 如果大于，单卡的比特率值为，单卡值*设置的值/几张卡的总值
        if (totalBit > sourceData.bitrate) {
          realTimeParams.slotDetail.length &&
            realTimeParams.slotDetail.map(v => {
              v.speed = Math.round((v.speed * sourceData.bitrate) / totalBit)
            })
        }
      }
      let formDetail = {
        signalStrength,
        makerPos,
        addressValue,
        remark
      }
      this.formDetail = formDetail
      let slotInfo = realTimeParams.slotInfo || []
      let voiceInfo = realTimeParams.voiceInfo || {}
      let date
      if (time) date = Tool.getDateTime(new Date(Number(time)))
      let strDate = ''
      // 右下角显示的最新编辑时间

      if (Object.keys(this.mapSourceData).length != 0 && !sourceData.deviceName && this.mapSourceData.createTime) {
        let editTime = new Date(this.mapSourceData.createTime) // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
        var Y = editTime.getFullYear() + '.'
        var M = (editTime.getMonth() + 1 < 10 ? '0' + (editTime.getMonth() + 1) : editTime.getMonth() + 1) + '.'
        var D = (editTime.getDate() < 10 ? '0' + editTime.getDate() : editTime.getDate()) + ' '
        var h = (editTime.getHours() < 10 ? '0' + editTime.getHours() : editTime.getHours()) + ':'
        var m = (editTime.getMinutes() < 10 ? '0' + editTime.getMinutes() : editTime.getMinutes()) + ':'
        var s = editTime.getSeconds() < 10 ? '0' + editTime.getSeconds() : editTime.getSeconds()
        strDate = Y + M + D + h + m + s
      }

      const strInfo = `<div class="map-content map_gps clearfix">
        <div class="vedio-msg">
          <img class='img-map' alt='error' onerror=${this.deafultImg}  src=${realTimeParams.img || ''}/>
          <div class="t_thumbnail_view_item_icon liveBg ${realTimeParams.status == 2 ? '' : 'hide'}">
            ${realTimeParams.status == 2 ? this.$t('lang.home_Live') : ''}
          </div>
          <div class="audio_column">
            <div class="audio_scale">
              <p class='audio_scale_num'>
                <span class='scale_12'>+12</span>
              </p>
              <p class='audio_scale_num'>
                <span class=''></span>
              </p>
              <p class='audio_scale_num'>
                <span class=''></span>
              </p>
              <p class='audio_scale_num'>
                <span class='scale_0'>0</span>
              </p>
              <p class='audio_scale_num'>
                <span class=''></span>
              </p>
              <p class='audio_scale_num'>
                <span class=''></span>
              </p>
              <p class='audio_scale_num'>
                <span class='scale_minus_12'>-12</span>
              </p>
              <p class='audio_scale_num'>
                <span class=''></span>
              </p>
              <p class='audio_scale_num'>
                <span class='scale_minus_20'>-20</span>
              </p>
              <p class='audio_scale_num'>
                <span class=''></span>
              </p>
              <p class='audio_scale_num'>
                <span class=''></span>
              </p>
              <p class='audio_scale_num'>
                <span class=''></span>
              </p>
              <p class='audio_scale_num'>
                <span class=''></span>
              </p>
              <p class='audio_scale_num'>
                <span class='scale_minus_40'>-40</span>
              </p>
          </div>
          <div class="audio_col audio_col_one">
            <div class="volume_bar">
              <div class="progress-bar" style="width:${(Number(voiceInfo.LeftDb || -40) + 40) * 1.923}%"></div>
            </div>
            <div class="volume_bar">
              <div class="progress-bar" style="width:${(Number(voiceInfo.RightDb || -40) + 40) * 1.923}%"></div>
            </div>
          </div>
        </div>
        <div class="date_box">
          <i class="el-icon-date"></i>
          <span>${date ? date.year + '.' + date.month + '.' + date.day + ' ' + date.hour + ':' + date.min + ':' + date.s : ''}</span>
        </div>
        <div class="device_detail">
          <p>
            ${this.$t('lang.type')}: ${realTimeParams.type || ''}
          </p>
          <p>
            ${this.$t('lang.format')}: ${realTimeParams.format || ''}
          </p>
          <p>${this.$t('lang.bitrate')}:
            <span class=${this.showBitClass(sourceData, realTimeParams)}>
              ${realBit || '0'}
            </span>
            /
            ${sourceData.bitrate || ''} kbps
          </p>
          <p>${this.$t('lang.delay')}: ${realTimeParams.delay || ''}</p>
          <p>${this.$t('lang.error')}: ${realTimeParams.error || '0'}%</p>
          <p>${this.$t('lang.quality')}: ${realTimeParams.quality || '0'}%</p>
          <div class="slot">
            <ul class="card-info row">
              <li class=""><i class='${slotInfo[0] == 1 ? 'one' : ''} ${slotInfo[0] == 2 ? 'two' : ''} ${slotInfo[0] == 3 ? 'three' : ''}'></i></li>
              <li class=""><i class='${slotInfo[1] == 1 ? 'one' : ''} ${slotInfo[1] == 2 ? 'two' : ''} ${slotInfo[1] == 3 ? 'three' : ''}'></i></li>
              <li class=""><i class='${slotInfo[2] == 1 ? 'one' : ''} ${slotInfo[2] == 2 ? 'two' : ''} ${slotInfo[2] == 3 ? 'three' : ''}'></i></li>
              <li class=""><i class='${slotInfo[3] == 1 ? 'one' : ''} ${slotInfo[3] == 2 ? 'two' : ''} ${slotInfo[3] == 3 ? 'three' : ''}'></i></li>
              <li class=""><i class='${slotInfo[4] == 1 ? 'one' : ''} ${slotInfo[4] == 2 ? 'two' : ''} ${slotInfo[4] == 3 ? 'three' : ''}'></i></li>
              <li class=""><i class='${slotInfo[5] == 1 ? 'one' : ''} ${slotInfo[5] == 2 ? 'two' : ''} ${slotInfo[5] == 3 ? 'three' : ''}'></i></li>
            </ul>
          </div>
          <div>
            <i class='iconfont icon-zoomin icon_operation' onClick='extendSourceDetail()'></i>
          </div>
        </div>
        <div class='extend_detail hide'>
          <div class='source_detail clearfix'>
            <p>
                ${this.$t('lang.type')}: ${realTimeParams.type || ''}
              </p>
              <p>
                ${this.$t('lang.format')}: ${realTimeParams.format || ''}
              </p>
              <p>${this.$t('lang.bitrate')}:
                <span class=${this.showBitClass(sourceData, realTimeParams)}>
                  ${realBit || '0'}
                </span>
                /
                ${sourceData.bitrate || ''} kbps
              </p>
              <p>${this.$t('lang.delay')}: ${realTimeParams.delay || ''}</p>
              <p>${this.$t('lang.error')}: ${realTimeParams.error || '0'}%</p>
              <p>${this.$t('lang.quality')}: ${realTimeParams.quality || '0'}%
              </p>
          </div>
          <div class='slot slot_detail'>
            <p class='slot_title'>
              <span class='slot_item'>Slot</span>
              <span class='carrier_name'>Carrier</span>
              <span class='slot_bit'>Bitrate (kbps)</span>
              <span>RTT(ms)</span>
            </p>
            <p>
              <span class='slot_item'>1</span>
              <i class='${slotInfo[0] == 1 ? 'one' : ''} ${slotInfo[0] == 2 ? 'two' : ''} ${slotInfo[0] == 3 ? 'three' : ''}'></i>
              <span class='carrier_name ellipsis'>${(realTimeParams.slotDetail && realTimeParams.slotDetail[0].carrierName) || this.$t('lang.noDevice')}</span>
              <span class='slot_bit'>${(realTimeParams.slotDetail && realTimeParams.slotDetail[0].speed) || 0} kbps</span>
              <span class=''>${(realTimeParams.slotDetail && realTimeParams.slotDetail[0].rttInMsec) || ''}</span>
            </p>
            <p>
              <span class='slot_item'>2 </span>
              <i class='${slotInfo[1] == 1 ? 'one' : ''} ${slotInfo[1] == 2 ? 'two' : ''} ${slotInfo[1] == 3 ? 'three' : ''}'></i>
              <span class='carrier_name ellipsis'>${(realTimeParams.slotDetail && realTimeParams.slotDetail[1].carrierName) || this.$t('lang.noDevice')}</span>
              <span class='slot_bit'>${(realTimeParams.slotDetail && realTimeParams.slotDetail[1].speed) || 0} kbps</span>
              <span class=''>${(realTimeParams.slotDetail && realTimeParams.slotDetail[1].rttInMsec) || ''}</span>
            </p>
            <p>
              <span class='slot_item'>3 </span>
              <i class='${slotInfo[2] == 1 ? 'one' : ''} ${slotInfo[2] == 2 ? 'two' : ''} ${slotInfo[2] == 3 ? 'three' : ''}'></i>
              <span class='carrier_name ellipsis'>${(realTimeParams.slotDetail && realTimeParams.slotDetail[2].carrierName) || this.$t('lang.noDevice')}</span>
              <span class='slot_bit'>${(realTimeParams.slotDetail && realTimeParams.slotDetail[2].speed) || 0} kbps</span>
              <span class=''>${(realTimeParams.slotDetail && realTimeParams.slotDetail[2].rttInMsec) || ''}</span>
            </p>
            <p>
              <span class='slot_item'>4 </span>
              <i class='${slotInfo[3] == 1 ? 'one' : ''} ${slotInfo[3] == 2 ? 'two' : ''} ${slotInfo[3] == 3 ? 'three' : ''}'></i>
              <span class='carrier_name ellipsis'>${(realTimeParams.slotDetail && realTimeParams.slotDetail[3].carrierName) || this.$t('lang.noDevice')}</span>
              <span class='slot_bit'>${(realTimeParams.slotDetail && realTimeParams.slotDetail[3].speed) || 0} kbps</span>
              <span class=''>${(realTimeParams.slotDetail && realTimeParams.slotDetail[3].rttInMsec) || ''}</span>
            </p>
            <p>
              <span class='slot_item'>5 </span>
              <i class='${slotInfo[4] == 1 ? 'one' : ''} ${slotInfo[4] == 2 ? 'two' : ''} ${slotInfo[4] == 3 ? 'three' : ''}'></i>
              <span class='carrier_name ellipsis'>${(realTimeParams.slotDetail && realTimeParams.slotDetail[4].carrierName) || this.$t('lang.noDevice')}</span>
              <span class='slot_bit'>${(realTimeParams.slotDetail && realTimeParams.slotDetail[4].speed) || 0} kbps</span>
              <span class=''>${(realTimeParams.slotDetail && realTimeParams.slotDetail[4].rttInMsec) || ''}</span>
            </p>
            <p>
              <span class='slot_item'>6 </span>
              <i class='${slotInfo[5] == 1 ? 'one' : ''} ${slotInfo[5] == 2 ? 'two' : ''} ${slotInfo[5] == 3 ? 'three' : ''}'></i>
              <span class='carrier_name ellipsis'>${(realTimeParams.slotDetail && realTimeParams.slotDetail[5].carrierName) || this.$t('lang.noDevice')}</span>
              <span class='slot_bit'>${(realTimeParams.slotDetail && realTimeParams.slotDetail[5].speed) || 0} kbps</span>
              <span class=''>${(realTimeParams.slotDetail && realTimeParams.slotDetail[5].rttInMsec) || ''}</span>
            </p>
          </div>
          <div>
            <i class='iconfont icon-zoomout icon_operation' onClick='closeSourceDetail()'></i>
          </div>
        </div>
      </div>
      <div class='map-al-form'>
        <div class="map-form-list">
          <div>
            <span class="iconfont icon-wifi ${sourceData.deviceName && 'hide'}"></span>
            <span class="signal-content ${sourceData.deviceName && 'hide'}">${singnalFormatter}</span>
            <span class='${!sourceData.deviceName && 'hide'}'>
              <span class='iconfont'>&#xe641;</span>
              ${sourceData.deviceName}
            </span>
            <span class="iconfont icon-12-copy-copy f_right ${sourceData.deviceName && 'hide'}"" onClick="editMaker()"></span>
            <span class="iconfont icon-leave f_right ${sourceData.deviceName && 'hide'}"" onClick="deleteMaker()"></span>
          </div>
        <div>
          <span class="iconfont icon-locationgray"></span>
          <span>N ${makerPos.latitude} E ${makerPos.longitude}</span>
        </div>
        <div>
          <span style="margin-left:20px;" class="address-detail">${addressValue}</span>
        </div>
        <div>
          <span class="iconfont  icon-copy icon-remark"></span>
          <span class="remark-detail">${remark}</span>
        </div>
        <div class="edit-time">${strDate}</div>
      </div>
    </div>`
      return strInfo
    },
    hideDelete () {
      // 系统自动打点
      if ((Object.prototype.hasOwnProperty.call(this.windowDetail, 'realTimeParams') &&
          JSON.parse(this.windowDetail.realTimeParams).signalStrength === '2') ||
        (Object.prototype.hasOwnProperty.call(this.windowDetail, 'realTimeParams') &&
          JSON.parse(this.windowDetail.realTimeParams).signalStrength === '3')) {
        // 设置删除按钮不显示
        $('.icon-leave').css('display', 'none')
      } else if (JSON.parse(this.windowDetail.realTimeParams).signalStrength == '') {
        setTimeout(() => {
          $('.icon-leave').css('display', 'none')
        }, 1)
      } else if (!Object.prototype.hasOwnProperty.call(this.windowDetail, 'realTimeParams')) {
        // 设置删除按钮不显示
        $('.icon-leave').css('display', 'none')
      }
      if (this.windowDetail.signalStrength) {
        setTimeout(() => {
          $('.icon-leave').css('display', 'block')
        }, 1)
      }
      switch (this.singnalString) {
        case 'Bad':
          $('.signal-content').css({ color: '#FFB700;' })
          break
        case 'Good':
          $('.signal-content').css({ color: '#1EC345;' })
          break
        case 'very bad':
          $('.signal-content').css({ color: '#E34D51;' })
          break
        case 'worse':
          $('.signal-content').css({ color: '#F07D03;' })
          break
        default:
          // 这里是没有找到对应的值处理
          break
      }
    },
    // 保存点
    saveMaker (lng, lat) {
      let formData = {}
      let pos = {
        longitude: lng,
        latitude: lat
      }
      formData = {
        signalStrength: $('.map-form-list select')[$('.map-form-list select').length - 1].value && $('.map-form-list select')[$('.map-form-list select').length - 1].value.trim(),
        address: $('.map-al-form .form-input')[$('.map-al-form .form-input').length - 1].value.trim(),
        remark: $('.map-al-form .form-textarea')[$('.map-al-form .form-textarea').length - 1].value.trim()
      }
      this.savePoint(this.mapSourceData, formData, pos)
    },
    // 编辑maker
    editMaker () {
      let data = this.formDetail
      let strFirst = ''
      let flag = this.singnalString

      if (flag === 'Good' || flag === 'Bad') {
        let selectValue = flag === 'Good'
        strFirst = `<div><span class="iconfont icon-wifi"></span>
          <select class='select-form'>
            <option style="color:#FFB700" value="1" ${!selectValue ? 'selected' : ''}>${this.$t('lang.bad')}</option>
            <option style="color:#1EC345" value="0" ${selectValue ? 'selected' : ''}>${this.$t('lang.good')}</option>
          </select>
        </div>`
      } else if (flag === 'very bad') {
        strFirst = `<div>
          <span class="iconfont icon-wifi"></span>
          <span style="color:#E34D51">${this.$t('lang.veryBad')}</span></div>`
      } else if (flag === 'worse') {
        strFirst = `<div>
          <span class="iconfont icon-wifi"></span>
          <span style="color:#F07D03">${this.$t('lang.worse')}</span>
        </div>`
      }
      let str = strFirst + `<div>
          <span class="iconfont icon-locationgray"></span>
          <span>N ${data.makerPos.latitude} E ${data.makerPos.longitude}</span>
        </div>
        <div>
          <input type="text" name='address' placeholder=${this.$t('lang.address')} class="form-input" maxlength="255" />
        </div>
        <div>
          <span class="iconfont  icon-copy icon-remark" style="top:-54px;"></span>
          <textarea placeholder=${this.$t('lang.remark')} rows="3" cols="20" class="form-textarea" maxlength="255">${data.remark}</textarea>
        </div>
        <div class="footer-button">
          <button class="map-cancel"  onClick="cancelModal()">${this.$t('lang.cancel')}</button>
          <button class="map-save" onClick="saveMaker(${data.makerPos.longitude},${data.makerPos.latitude})"> ${this.$t('lang.save')}</button>
        </div>`
      $('.map-form-list').html(str)
      // 设置input的值

      $('.form-input').val(this.formDetail.addressValue)
    },
    // 点击弹框取消
    cancelModal () {
      $('.gm-style-iw-t').hide()
    },
    // 点击删除marker
    deleteMaker () {
      for (let i = 0; i < this.allPositionData.length; i++) {
        if (this.saveTime == this.allPositionData[i].timestamp) {
          this.allPositionData.splice(i, 1)
        }
      }
      for (let i = 0; i < this.allMarkData.length; i++) {
        if (this.saveTime == this.allMarkData[i].timestamp) {
          this.allMarkData.splice(i, 1)
        }
      }
      for (let i = 0; i < this.manualOrange.length; i++) {
        if (this.saveTime == this.manualOrange[i].timestamp) {
          this.manualOrange.splice(i, 1)
        }
      }
      for (let i = 0; i < this.manualGreen.length; i++) {
        if (this.saveTime == this.manualGreen[i].timestamp) {
          this.manualGreen.splice(i, 1)
        }
      }
      $('.gm-style-iw-t').hide()
      let slotinfo = []
      this.sourceDataObj.slotinfo &&
        this.sourceDataObj.slotinfo.AdapterModels.map(v => {
          slotinfo.push(v.Status)
        })
      let QualityMeasureOnGUI = this.sourceDataObj.receiverdata.QualityMeasureOnGUI
      if (QualityMeasureOnGUI === 0) {
        QualityMeasureOnGUI = String(QualityMeasureOnGUI)
      }

      const sourceData = {
        peerId: this.$route.query.peerId,
        bitrate: (this.sourceDataObj.setinfo.CurrentSetting.Bitrate && String(this.sourceDataObj.setinfo.CurrentSetting.Bitrate)) || '',
        longitude: this.mapSourceData.longitude || '',
        latitude: this.mapSourceData.latitude || '',
        realTimeParams: JSON.stringify({
          QualityMeasureOnGUI: QualityMeasureOnGUI || '',
          type: this.sourceDataObj.currentrinfo.CurrentTInputType.ReadableName,
          format: this.sourceDataObj.currentrinfo.CurrentTFormat.ReadableName,
          delay: this.getDelay((this.sourceDataObj.setinfo.CurrentSetting.Delay / 1000).toFixed(1),
            this.sourceDataObj.setinfo.CurrentTCanSetDelay
          ),
          error: this.sourceDataObj.receiverdata.ErrorRateLastSec,
          quality: this.sourceDataObj.receiverdata.ReSendRateLastSec,
          slotInfo: slotinfo,
          img: '',
          signalStrength: '',
          voiceInfo: this.sourceDataObj.volumechart,
          status: this.currentSource.status
        }),
        signalStrength: '',

        timestamp: this.saveTime,
        slotDetail: this.slotDetail
      }
      if (sourceData.realTimeParams && sourceData.realTimeParams.length >= 512) return
      this.axios.post('/ccp/tvucc-device/geoRecord/saveGeoRecord', sourceData, {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        }
      }).then(async res => {
        if (res.data.errorCode == '0x0') {
          // 更新本地的allMarkdata
          let markType = 'add'
          for (let val of this.allMarkData) {
            if (val.timestamp == sourceData.timestamp) {
              val = sourceData
              markType = 'edit'
              break
            }
          }
          // 如果找不到数据就添加
          if (markType == 'add') {
            this.allMarkData.push(sourceData)
          }
          return true
        }
        return false
      }).catch(() => {
        return false
      })
      this.deleteStatus = true
      if (this.historyFlag) {
        this.getHistory()
      } else {
        this.makeLine()
      }
      const centerPoint = new google.maps.LatLng(
        Number(this.mapSourceData.latitude),
        Number(this.mapSourceData.longitude)
      )
      this.mapObj.setCenter(centerPoint)
    },
    async makeLine (val) {
      if (val) {
        if (val.timeType === '0') {
          this.params.starttime = this.interval;
          (this.params.endtime || this.params.endtime === 0) && delete this.params.endtime
        }
      } else {
        if (this.$route.query.timeType === '0') {
          this.params.starttime = this.interval;
          (this.params.endtime || this.params.endtime === 0) && delete this.params.endtime
        }
      }
      await this.axios.get(`${this.url}/Out/getGPSTrackingServlet`, { params: this.params }, {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        }
      }).then(async res => {
        if (!res.data || (typeof res.data == 'string' && !res.data.trim())) return
        if (this.$route.query.timeType === '0') {
          // 如果返回了手动设置的点，比较返回时间与当前时间是否相差超过一定范围
          const incrementalRangeTime = localStorage.getItem(
            'incrementalRangeTime'
          )
          const manualIncrementTime = localStorage.getItem(
            'manualIncrementTime'
          )
          const currentGps = res.data.trim().split('\r\n')
          const currentTime = new Date().getTime()
          let removeGps = []
          for (let i = 0; i < currentGps.length; i++) {
            const v = currentGps[i]
            if (v.trim() === '') {
              removeGps.push(i)
              continue
            }
            const currentData = v.split(',')
            // 返回时间与当前时间相差超过一定范围
            if (Math.abs(currentTime - currentData[3]) > incrementalRangeTime) {
              // 返回时间-固定值的差值是否在一定范围内，如果不在，则去掉这个点
              if (Math.abs(currentTime - Math.abs(currentData[3] - manualIncrementTime)) > incrementalRangeTime) {
                removeGps.push(i)
              } else {
                // 在则更改这个点的时间戳
                currentData[3] = String(currentData[3] - manualIncrementTime)
                currentGps[i] = currentData.join(',')
              }
            }
          }
          let removeNum = 0
          removeGps.map(v => {
            currentGps.splice(v - removeNum, 1)
            removeNum++
          })
          if (!currentGps.length) return
          res.data = currentGps.join('\r\n')
        }
        let infos = null

        if (res.data !== '') {
          infos = res.data.trim().split('\r\n')
        } else {
          return
        }

        // 间隔10秒及其以上
        let filterInfo = []
        for (let i = 0; i < infos.length; i++) {
          const item = infos[i]
          if (typeof item && item.trim() === '') continue
          const v = item.split(',')
          const lastPoint = this.firstData[this.firstData.length - 1] && this.firstData[this.firstData.length - 1].split(',')
          if (!filterInfo.length) {
            if (!lastPoint || v[3] - lastPoint[3] >= this.spaceTime) {
              filterInfo.push(v.join(','))
            }
            continue
          }
          if (v[3] - filterInfo[filterInfo.length - 1][3] >= this.spaceTime) {
            filterInfo.push(v.join(','))
          }
        }
        // 如果返回的实时点不满足间隔10秒的条件，return
        if (!filterInfo.length) return
        infos = JSON.parse(JSON.stringify(filterInfo))

        // 根据时间戳保存source详情
        if (this.currentSource.status == 2 && this.$route.query.timeType == '0') {
          let lastPointData = ''
          for (let j = infos.length - 1; j >= 0; j--) {
            if (infos[j].trim() != '') {
              lastPointData = infos[j]
              break
            }
          }
          // 如果源不在线了，停止保存
          if (res.data && infos.length) {
            await this.saveSourceData(lastPointData.split(','))
          }
        }

        if (this.firstData.length !== 0 && this.firstFlag) {
          if (this.firstData[this.firstData.length - 1] !== infos[0]) {
            infos.unshift(this.firstData[this.firstData.length - 1])
          } else {
            for (var i = this.firstData.length - 2; i > 0; i--) {
              if (this.firstData[i] !== infos[0]) {
                infos.unshift(this.firstData[i])
                break
              }
            }
          }
        }
        this.firstFlag++

        this.firstData = this.firstData.concat(filterInfo)
        // 间隔时间为10秒以上的才显示点
        let gpsPoints = [] // gps返回的点
        let filterGpsArr = []
        this.firstData.map(v => {
          if (!v) return
          const gpsPoint = v.split(',')
          gpsPoints.push({
            longitude: gpsPoint[1],
            latitude: gpsPoint[0],
            timestamp: gpsPoint[3]
          })
          if (!filterGpsArr.length) {
            filterGpsArr.push(v)
          }
          if (v.split(',')[3] - filterGpsArr[filterGpsArr.length - 1].split(',')[3] >= this.spaceTime) filterGpsArr.push(v)
        })
        this.firstData = filterGpsArr

        const allPoints = gpsPoints.concat(this.allMarkData)

        allPoints.sort((m, n) => {
          return m.timestamp - n.timestamp
        })
        let filterPoints = []
        allPoints.map((v, i) => {
          if (!filterPoints.length) filterPoints.push(v)
          if (v.timestamp - filterPoints[filterPoints.length - 1].timestamp == 0 && v.id) {
            filterPoints[filterPoints.length - 1] = v
          }
          if (v.timestamp - filterPoints[filterPoints.length - 1].timestamp >= this.spaceTime) {
            filterPoints.push(v)
          }
        })
        this.allPositionData = filterPoints
        let flightPlanCoordinates = []
        this.timestamps = []
        if (infos.length !== 0 && infos !== '') {
          this.startNum++
        }
        for (const i in infos) {
          var ainfo = infos[i].split(',')
          if (Number(ainfo[1]) <= 180 && Number(ainfo[0]) <= 90) {
            // 谷歌
            flightPlanCoordinates.push({
              lat: Number(ainfo[0]),
              lng: Number(ainfo[1])
            })
          }
        }
        // 画线
        const flightPath = new google.maps.Polyline({
          path: flightPlanCoordinates,
          strokeColor: '#18A45B',
          strokeOpacity: 1.0,
          strokeWeight: 4
        })
        flightPath.setMap(this.mapObj)
        flightPath.addListener('click', async e => {
          this.clickPolyline(e)
        })

        if (flightPlanCoordinates.length === this.allPositionData.length) {
          const startMarker = new google.maps.Marker({
            position: flightPlanCoordinates[0],
            icon: require('@/assets/img/map/start_point.svg')
          })
          startMarker.setMap(this.mapObj)
          startMarker.addListener('click', e => {
            this.clickPolyline(e)
          })
        }
        // 设置这次返回的终点icon
        const iconObj = {
          red: require('@/assets/img/map/location_red.svg'),
          orange: require('@/assets/img/map/location_yellow.svg'),
          ordinary: require('@/assets/img/map/map_point.svg')
        }
        const marker = new google.maps.Marker({
          position: flightPlanCoordinates[flightPlanCoordinates.length - 1],
          icon: iconObj[this.iconType || 'ordinary']
        })
        marker.setMap(this.mapObj)
        // 给点添加点击事件
        marker.addListener('click', e => {
          this.clickPolyline(e)
        })
        // 设置中心点
        const lastMarker = flightPlanCoordinates[flightPlanCoordinates.length - 1]
        const centerPoint = new google.maps.LatLng(
          lastMarker.lat,
          lastMarker.lng
        )
        this.mapObj.setCenter(centerPoint)
      }).catch(() => {})
    },
    // 点击线上的点
    async clickPolyline (e) {
      $('.gm-style-iw-t').hide()
      const position = {
        y: e.latLng.lng(),
        x: e.latLng.lat()
      }
      let timestamp = null
      if (this.allMarkData.length !== 0) {
        timestamp = this.getTimestamp(position, this.allMarkData, true)
        if (!timestamp) {
          // timestamp为空时
          timestamp = this.getTimestamp(
            position,
            this.allPositionData
          )
        }
      } else {
        timestamp = this.getTimestamp(position, this.allPositionData)
      }
      this.infoWindow = new google.maps.InfoWindow()
      const strInfo = await this.openSourceDetail(timestamp, position)
      this.infoWindow.setContent(strInfo)
      this.infoWindow.setPosition(e.latLng)
      this.infoWindow.open(this.mapObj)
      setTimeout(() => {
        // 系统自动打点设置删除按钮不显示
        this.hideDelete()
      })
    },
    //  初始化中心点坐标
    async initCenter (val) {
      let position = {}
      this.axios
        .get(`${this.url}/Out/getGPSBypeeridAndTimestampServlet?peerid=${(val && val.peerId) || this.$route.query.peerId}`)
        .then(res => {
          if (res.status == 200 && res.data) {
            position = res.data[0]
          }
          this.initGoogleMap(position.latitude, position.longitude)
        })
        .catch(() => {
          this.initGoogleMap()
        })
    },
    // 匹配经纬度
    getTimestamp (position, infos, isMarkData) {
      // 精确匹配
      for (let i = 0; i < infos.length; i++) {
        const dataObj = infos[i]
        if (Number(dataObj.latitude) === Number(position.x) && Number(dataObj.longitude) === Number(position.y)) {
          return dataObj.timestamp
        }
      }
      let differArr = []
      // 模糊匹配
      for (let j = 0; j < infos.length; j++) {
        const dataObj = infos[j]
        const differItem = {
          differ: this.getDistance(
            dataObj.latitude,
            dataObj.longitude,
            position.x,
            position.y
          ),
          time: dataObj.timestamp
        }
        differArr.push(differItem)
      }
      differArr.sort((m, n) => {
        return m.differ - n.differ
      })
      if (isMarkData) {
        return (
          differArr[0] &&
          differArr[0].differ <= 1 &&
          differArr[0].time
        )
      }
      return differArr[0] && differArr[0].time
    },
    getRad (d) {
      var PI = Math.PI
      return (d * PI) / 180.0
    },
    getDistance (lat1, lng1, lat2, lng2) {
      lat1 = Number(lat1)
      lng1 = Number(lng1)
      lat2 = Number(lat2)
      lng2 = Number(lng2)
      var f = this.getRad((lat1 + lat2) / 2)
      var g = this.getRad((lat1 - lat2) / 2)
      var l = this.getRad((lng1 - lng2) / 2)
      var sg = Math.sin(g)
      var sl = Math.sin(l)
      var sf = Math.sin(f)
      var s, c, w, r, d, h1, h2
      var a = 6378137.0 // The Radius of eath in meter.
      var fl = 1 / 298.257
      sg = sg * sg
      sl = sl * sl
      sf = sf * sf
      s = sg * (1 - sl) + (1 - sf) * sl
      c = (1 - sg) * (1 - sl) + sf * sl
      w = Math.atan(Math.sqrt(s / c))
      r = Math.sqrt(s * c) / w
      d = 2 * w * a
      h1 = (3 * r - 1) / 2 / c
      h2 = (3 * r + 1) / 2 / s
      s = d * (1 + fl * (h1 * sf * (1 - sg) - h2 * (1 - sf) * sg))
      s = s / 1000
      s = s.toFixed(2) // 指定小数点后的位数。
      return s
    },
    // 处理坐标不正确的问题
    async tranlatePoint (points, call) {
      var tempArr = [] // 转换完成后的坐标数组
      var total = 0 // 总记录数
      var groupCount = 0 // 每次转十条
      if (points.length % 10 > 0) {
        groupCount = Math.floor(points.length / 10) + 1
      } else {
        groupCount = points.length / 10
      }
      for (let i = 0; i < groupCount; i++) {
        // 外层循环，有多少组十条
        var pos = []
        for (var j = 0; j < 10; j++) {
          // 内层循环，每组十条
          if (total < points.length) {
            // 不超过总记录数结束
            var point = new BMap.Point(
              points[i * 10 + j].lng,
              points[i * 10 + j].lat
            )
            pos.push(point)
          }
          total++
        }
        var convertor = new BMap.Convertor()
        await convertor.translate(pos, 3, 5, function (data) {
          if (data.status === 0) {
            for (var k = 0; k < data.points.length; k++) {
              tempArr.push(data.points[k])
            }
            if (i == groupCount - 1) {
              setTimeout(() => {
                call(tempArr)
              }, 500)
            }
          }
        })
      }
    },
    extendSourceDetail () {
      $('.device_detail').addClass('hide')
      $('.audio_column').addClass('hide')
      $('.date_box').addClass('hide')
      $('.extend_detail').removeClass('hide')
    },
    closeSourceDetail () {
      $('.device_detail').removeClass('hide')
      $('.audio_column').removeClass('hide')
      $('.date_box').removeClass('hide')
      $('.extend_detail').addClass('hide')
    }
  },
  // 路由离开之前先关闭定时器
  beforeRouteLeave (to, from, next) {
    sessionStorage.removeItem('collect')
    if (this.timer) {
      clearInterval(this.timer)
    }
    if (this.timeOut) {
      clearInterval(this.timeOut)
    }
    if (this.initBaiduTimers) {
      clearInterval(this.initBaiduTimers)
    }
    if (this.initTimers) {
      clearInterval(this.initTimers)
    }
    if (this.$createWebsocket.pageRequest) {
      this.$createWebsocket.pageRequest('stop', [
        'taskInfo_T_S',
        'detailInfo'
      ]) // 停止websocket
    }
    if (this.$createWebsocket.pageRWebsocket) {
      this.currentSource.status = null
      this.iconType = ''
      this.$createWebsocket.pageRWebsocket('stop', [
        'VolumeChart',
        'slotInfo',
        'receiverData',
        'currentRInfo',
        'setInfo'
      ]) // 停止websocket
    }
    next()
  }
}
</script>
<style lang="less"></style>
<style lang="less" scoped>
p {
  margin: 0;
}
#mapGoogle {
  height: 100%;
  width: 100%;
  overflow: auto;

  ::v-deep .gm-style-iw.gm-style-iw-c {
    padding: 0;
    height: 205px;
    .gm-style-iw-d > div {
      width: 100%;
    }
  }
}
</style>
