<template>
  <div
    style="width: 100%; height: 100%; overflow-x: hidden"
    v-if='baiduStatus'>
    <div
      v-if="this.lang === 'zh'"
      style='width: 100%; height: 100%;'
      v-loading="loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <div id="mapContent"></div>
    </div>
    <gmap-track
      v-else
      ref="mapTrack"
      :tracking='tracking'
    ></gmap-track>
  </div>
</template>
<script>
import Tool from '@/assets/js/utils'
import { mapState } from 'vuex'
import gmapTrack from './gmapTrack'
import Bus from '@/assets/js/vueBus.js'

export default {
  components: {
    gmapTrack
  },
  props: {
    mapQueryObj: {
      type: Object,
      default: function () {
        return {}
      }
    },
    tracking: Object
  },
  data () {
    return {
      lang: 'zh',
      bounds: [],
      map: null,
      isWheel: true,
      wheelZoom: 3,
      timestamps: [],
      maxTime: 0,
      imgUrl: '',
      formDetail: {}, // 存储maker详情
      mapSourceData: [],
      historyFlag: false, // 历史数据flag
      signal: '', // 信号强弱
      windowDetail: {}, // 打开弹框的信息
      saveTime: '',
      interval: '500000_1',
      spaceTime: 5000,
      mapType: 'baidu',
      loading: false,
      deafultImg: 'this.src="' + require('@/assets/img/logo_thumbnail.png') + '"',
      url: `${JSON.parse(localStorage.getItem('newCCInitConfig')).urlInfo.frontGeoServiceUrl}`,
      firstFlag: 0,
      testNum: 0,
      endPoints: [], // 实时记录终点数组
      startPoints: [], // 实时记录起点数组
      manualOrange: [], // 手动打点记录黄色
      manualGreen: [], // 手动打点绿色
      singnalString: '', // 信号显示的问题内容
      iconType: '',
      startNum: 0,
      BMapTimer: 0,
      sourceDataObj: {
        currentrinfo: {
          CurrentTInputType: {},
          CurrentTFormat: {}
        },
        setinfo: {
          CurrentSetting: {}
        },
        receiverdata: {},
        slotinfo: {
          AdapterModels: [{}, {}, {}, {}, {}, {}]
        }
      },
      firstData: [],
      form: {},
      allMarkData: [], // 所有的mark点
      allPositionData: [],
      currentSource: {}, // 当前source的实时信息
      slotDetail: [[], [], [], [], [], []],
      // lastPointTime: "", // 最后一个点的时间戳
      params: {
        peerid: this.$route.query.peerId,
        starttime: Date.parse(
          new Date(Number(this.$route.query.startTime))
        ).toString(),
        endtime: Date.parse(
          new Date(Number(this.$route.query.endTime))
        ).toString(),
        mapType: 'baidu'
      },
      openTPlatform: null,
      gpsDuration: 120,
      speedInfo: {},
      bitRate: null,
      allPosition: [],
      allDeviceLivePeerId: [],
      isDeviceWindow: false,
      deviceTimeFlag: true,
      baiduStatus: true,
      singnalArr: []
    }
  },

  created () {
    window.saveMaker = this.saveMaker
    window.deleteMaker = this.deleteMaker
    window.cancelModal = this.cancelModal
    window.editMaker = this.editMaker
    window.extendSourceDetail = this.extendSourceDetail
    window.closeSourceDetail = this.closeSourceDetail
    this.getInterval()
    if (process.env.NODE_ENV === 'development') {
      this.url = '/geoservice'
    }
    Bus.$on('langSeltSwitch', () => {
      this.init()
    })
  },
  mounted () {
    Bus.$on('changrLang', langSelt => {
      location.reload()
    })
    this.init()
  },
  computed: {
    ...mapState({
      StateObj: (state) => state,
      pageRequest: (state) => state.websocket.pageRequest,
      pageRWebsocket: (state) => state.websocket.pageRWebsocket
    })
  },
  beforeDestory () {
    this.iconType = ''
    clearInterval(this.timer)
    if (this.$createWebsocket.pageRequest) {
      this.$createWebsocket.pageRequest('stop', ['taskInfo_T_S', 'detailInfo']) // 停止websocket
    }
    if (this.$createWebsocket.pageRWebsocket) {
      this.$createWebsocket.pageRWebsocket('stop', [
        'VolumeChart',
        'slotInfo',
        'receiverData',
        'currentRInfo',
        'setInfo'
      ]) // 停止websocket
    }
  },
  watch: {
    // 更改参数
    tracking: {
      handler (val) {
        if (this.lang == 'en') return
        let query = {
          timeType: val.timeType,
          peerId: val.peerId,
          sourceType: val.sourceType,
          startTime: typeof val.startTime == 'object' ? (val.startTime && val.startTime.getTime()) || val.startTime : val.startTime || null,
          endTime: typeof val.endTime == 'object' ? (val.endTime && val.endTime.getTime()) || val.endTime : val.endTime || null,
          peerName: val.peerName
        }
        // 多路追踪
        if (val.timeType == '2') query.multiple = val.multiple
        this.$router.push({
          name: 'SourceMapTrack',
          query: query
        })
        setTimeout(() => {
          this.changeCondition(val)
          // 初始化更新设备值,为了防止close事件在筛选后触发
          this.isDeviceWindow = false
          this.deviceTimeFlag = true
        }, 1000)
      },
      deep: true
    }
  },
  methods: {
    init () {
      this.loading = true
      this.lang = window.localStorage.getItem('lang')
      if (this.lang === 'zh') {
        this.getSourceStatus()
        this.initMap()
      }
    },
    getInterval () {
      this.interval = `${(localStorage.getItem('delayQueryTime') || 10000) / 0.02}_1`
      this.spaceTime = localStorage.getItem('mapStep') || 5000
    },
    initMap () {
      Tool.loadMapJs(this.lang, async () => {
        this.loading = false
        if (typeof BMap === 'undefined') {
          if (this.BMapTimer < 5) {
            this.BMapTimer++
            this.initMap()
            return
          }
          this.$message.error(this.$t('lang.networkBad'))
          return
        }
        this.initialize()
        let timeParams = {}
        if (this.timer) clearInterval(this.timer)
        if (this.$route.query.timeType != '0') {
          timeParams.starttime = this.params.starttime
          timeParams.endtime = this.params.endtime == 0 ? delete this.params.endtime : this.params.endtime
        } else {
          this.gpsDuration = localStorage.getItem('gpsDuration') || 120
          timeParams.starttime = new Date().getTime() - this.gpsDuration * 60 * 1000
          timeParams.endtime = new Date().getTime()
        }
        if (this.$route.query.timeType === '2') {
          this.handleMultiple()
        } else {
          // 开启webscoket获取当前设备实时信息
          this.createMsgWebsocket()
        }
        // 选择历史数据
        if (this.$route.query.timeType != '0') {
          await this.getSourceData(timeParams.starttime, timeParams.endtime)
          await this.getAllMaker()
          Bus.$emit('allMarkerLength', this.allPositionData.length)
          this.getHistory()
          this.historyFlag = true
        } else {
          clearInterval(this.timer)
          this.startNum = 0
          this.makeLine()
          this.firstFlag = 0
          this.timer = setInterval(async () => {
            this.historyFlag = false
            this.makeLine()
          }, 10000)
        }
      })
    },
    initialize () {
      this.map = new BMap.Map('mapContent')
      this.initCenter()
      window._startTime = Date.parse(new Date())
      this.map.enableScrollWheelZoom(true) // 开启鼠标滚轮缩放
      this.map.disableDoubleClickZoom() // 关闭双击缩放
    },
    async changeCondition (val) {
      // 多路追踪时，查询所有设备的信息
      let peerIds = []
      if (val.timeType == '2') {
        val.multiple.split(',').map(v => {
          peerIds.push(v.split('-')[1])
        })
      }
      // 初始化信息
      this.firstData = []
      this.allMarkData = [] // 所有的mark点
      this.allPositionData = []
      this.currentSource = {} // 当前source的实时信息
      let taskInfoMsg = {
        type: 'taskInfo',
        module: 'taskInfo_T_S',
        data: { peerIds: peerIds.length ? peerIds.join(',') : val.peerId }
      }
      if (this.pageRequest.sendMsgs.taskInfo_T_S) {
        this.pageRequest.sendMsgs.taskInfo_T_S.message = taskInfoMsg
      }

      this.params.starttime = typeof val.startTime == 'object' ? (val.startTime && val.startTime.getTime()) || val.startTime : val.startTime
      this.params.endtime = typeof val.endTime == 'object' ? (val.endTime && val.endTime.getTime()) || val.endTime : val.endTime

      this.params.peerid = val.peerId
      let timeParams = {}
      if (this.timer) clearInterval(this.timer)
      // 百度地图
      if (this.lang === 'zh') {
        // 初始化中心点
        this.initCenter(val)
        // 历史数据
        if (val.timeType === '2') {
          this.handleMultiple(val.multiple)
        } else {
          // 开启webscoket获取当前设备实时信息
          this.createMsgWebsocket()
          if (this.$createWebsocket.pageRequest) {
            this.$createWebsocket.pageRequest('stop', ['getLocationByIps_hm']) // 停止websocket
          }
        }
        if (val.timeType != '0') {
          timeParams.starttime = this.params.starttime
          timeParams.endtime = this.params.endtime
          await this.getSourceData(timeParams.starttime, timeParams.endtime, val.peerId)
          await this.getAllMaker(val)
          Bus.$emit('allMarkerLength', this.allPositionData.length)
          this.historyFlag = true
          this.getHistory()
        } else if (val.timeType === '0') {
          this.map.clearOverlays()
          this.historyFlag = false
          this.gpsDuration = localStorage.getItem('gpsDuration') || 120
          this.makeLine(val)
          clearInterval(this.timer)
          this.startNum = 0
          this.firstFlag = 0
          this.timer = setInterval(async () => {
            this.makeLine()
          }, 10000)
        }
      } else if (this.lang === 'en') {
        this.$refs.mapTrack.init()
      }
    },
    getSlotBitrate (peerId, time, callback) {
      // 初始化值
      this.speedInfo = []
      this.slotDetail.map(v => {
        if (v.rttInMsec) v.rttInMsec = ''
        if (v.speed) v.speed = '0'
      })
      this.pageRequest && this.pageRequest.send({
        message: {
          type: 'detailInfo',
          module: 'detailInfo',
          data: { peerIds: peerId || this.$route.query.peerId }
        },
        time: time || 1000,
        key: 'detailInfo',
        isOnce: !!peerId,
        success: (res) => {
          if (!res.result) return
          let speedInfo = JSON.parse(res.result)[0].Slots
          if (!speedInfo) return
          let result = this.handleSlotAndRtt(speedInfo)
          if (callback) callback(result)
        }
      })
    },
    // 处理slot和rtt
    handleSlotAndRtt (speedInfo) {
      for (let i = 0; i < speedInfo.length; i++) {
        let v = speedInfo[i]
        let rttInMsec = v.rttInMsec || ''
        v.Speed = typeof v.Speed == 'string' && v.Speed.trim() || v.Speed
        let qualityVal = v.Speed && String(Math.round(v.Speed / 1024 * 8)) || '0'

        if (this.currentSource.platform >= 10 && this.currentSource.platform <= 14 && typeof v.SignalType == 'string' && !['hotspot', 'eth', 'wifi'].includes(v.SignalType.toLowerCase())) {
          v.CarrierName = v.CarrierName || v.SignalType
        }
        if (typeof v.SignalType == 'string' && v.CarrierName) {
          this.speedInfo[v.Index - 1] = { qualityVal, rttInMsec, carrierName: v.CarrierName }
        } else if (typeof v.SignalType == 'string' && v.SignalType.toLowerCase() == 'hotspot') {
          this.speedInfo[9] = { qualityVal, rttInMsec }
        } else if (typeof v.SignalType == 'string' && v.SignalType.toLowerCase() == 'eth') {
          this.speedInfo[8] = { qualityVal, rttInMsec }
        } else if (typeof v.SignalType == 'string' && v.SignalType.toLowerCase() == 'wifi') {
          this.speedInfo[10] = { qualityVal, rttInMsec }
        }
      }
      for (let i = 0; i < 13; i++) {
        this.speedInfo[i] = this.speedInfo[i] || { qualityVal: '0', rttInMsec: '', speed: '' }
        this.slotDetail[i] = this.speedInfo[i] || { qualityVal: '0', rttInMsec: '', speed: '' }
        this.slotDetail[i].speed = this.speedInfo[i] && this.speedInfo[i].qualityVal || ''
        this.slotDetail[i].rttInMsec = this.speedInfo[i] && this.speedInfo[i].rttInMsec || ''
      }
      return JSON.parse(JSON.stringify(this.slotDetail))
    },
    getSourceStatus () {
      // 多路追踪时，查询所有设备的信息
      let peerIds = []
      if (this.$route.query.timeType == '2') {
        this.$route.query.multiple.split(',').map(v => {
          peerIds.push(v.split('-')[1])
        })
      }
      this.pageRequest.send({
        message: {
          type: 'taskInfo',
          module: 'taskInfo_T_S',
          data: { peerIds: peerIds.length ? peerIds.join(',') : this.$route.query.peerId }
        },
        time: 1000,
        key: 'taskInfo_T_S',
        success: (res) => {
          if (this.$route.query.timeType == '2') {
            this.getMultipleDeviceInfo(res)
            return
          }
          // 如果当前源不live，停止websocket
          if (res.result[0].status != 2) {
            this.iconType = ''
            if (this.$createWebsocket.pageRWebsocket) {
              this.currentSource.status = res.result[0].status
              this.$createWebsocket.pageRWebsocket('stop', [
                'VolumeChart',
                'slotInfo',
                'receiverData',
                'currentRInfo',
                'setInfo'
              ])
            }
            if (this.$createWebsocket.pageRequest) {
              this.$createWebsocket.pageRequest('stop', ['detailInfo']) // 停止websocket
            }
            return
          }
          // 模式不为多路追踪时，如果当前源之前不在live，现在变为live状态，开启websocket
          if (this.currentSource.status != 2 && res.result[0].status == 2) {
            this.createMsgWebsocket()
          }
          this.currentSource = res.result[0]
          this.updateSocketParams('VolumeChart', '2152867840', '200') // 获取音量信息
          this.updateSocketParams('slotInfo', '2152865792', '101') // 获取slot信息
          this.updateSocketParams('receiverData', '2152857600', '101') // 获取err and quality信息
          this.updateSocketParams('currentRInfo', '2152857600', '102') // 获取type and format信息
          this.updateSocketParams('setInfo', '2152866048', '101') // 获取bitrate and delay信息
        }
      })
    },
    // 获取多路设备的实时信息
    getMultipleDeviceInfo (data) {
      this.allDeviceLivePeerId = []
      data && data.result.map(v => {
        this.allDeviceLivePeerId[v.peerId.toLowerCase()] = v
      })
    },
    // 更新websocket参数
    updateSocketParams (type, categoryId, operationType) {
      let message = {
        categoryId: categoryId,
        operationType: operationType,
        rid: this.currentSource.livePeerId
      }
      if (this.pageRWebsocket.sendMsgs && this.pageRWebsocket.sendMsgs[type]) {
        this.pageRWebsocket.sendMsgs[type].message = message
      }
    },
    createMsgWebsocket (livePeerId, time, peerId) {
      this.sendMsgWebsocket('VolumeChart', '2152867840', '200', time || 200, livePeerId) // 获取音量信息
      this.sendMsgWebsocket('SlotInfo', '2152865792', '101', time || 1000, livePeerId) // 获取slot信息
      this.sendMsgWebsocket('CurrentRInfo', '2152857600', '102', time || 1000, livePeerId) // 获取type and format信息
      this.sendMsgWebsocket('SetInfo', '2152866048', '101', time || 1000, livePeerId) // 获取bitrate and delay信息
      this.sendMsgWebsocket('ReceiverData', '2152857600', '101', time || 1000, livePeerId) // 获取err and quality信息
      this.getSlotBitrate(peerId, time)
    },
    sendMsgWebsocket (type, categoryId, operationType, time, livePeerId, callback) {
      let typeVal = ''
      if (type != 'VolumeChart') { typeVal = `${type[0].toLowerCase()}${type.slice(1, type.length)}` }
      this.pageRWebsocket.send({
        message: {
          categoryId: categoryId,
          operationType: operationType,
          rid: livePeerId || this.currentSource.livePeerId
        },
        time: time || 5000,
        key: typeVal || type,
        isOnce: !!livePeerId,
        success: (res) => {
          this.updateSourceData(type, JSON.parse(res.data))
          callback && callback()
          if (res.data && type === 'SlotInfo') {
            let slotData = JSON.parse(res.data).AdapterModels
            this.handleSlotInfo(slotData)
          }
          this.getSignalStrength(res, type)
        }
      })
    },
    // 获取信号强度
    getSignalStrength (res, type) {
      if (res.data && type === 'SetInfo') {
        if (this.sourceDataObj.receiverdata.QualityMeasureOnGUI === undefined) return
        this.bitRate = (this.sourceDataObj.receiverdata.QualityMeasureOnGUI / JSON.parse(res.data).CurrentSetting.Bitrate) * 100
        let flag = false
        if (this.bitRate > 40 && this.bitRate < 60) {
          flag = this.singnalArr.includes('red')
          this.iconType = flag ? 'red' : 'orange'
        }
        this.singnalArr.push(flag || this.iconType == 'orange' ? 'orange' : '')
        if (this.singnalArr.length >= 10) {
          this.singnalArr.shift()
        }
      } else if (res.data && type === 'ReceiverData') {
        let eightSeconds = this.sourceDataObj.receiverdata.ErrorRateLastNSec // 8s

        let oneSeconds = this.sourceDataObj.receiverdata.ErrorRateLastSec // 1s
        if (eightSeconds > 0 && oneSeconds > 0) {
          this.iconType = 'red'
        }
        if (eightSeconds == 0 && oneSeconds == 0 && this.bitRate >= 60) {
          this.iconType = ''
        }
        this.singnalArr.push(this.iconType)
        if (this.singnalArr.length >= 10) {
          this.singnalArr.shift()
        }
      }
    },
    // 处理slot信息
    handleSlotInfo (data) {
      if (data && data.length) {
        this.slotDetail = [[], [], [], [], [], []]
        for (let i = 0; i < data.length; i++) {
          let v = data[i]
          let platform = this.currentSource.platform || this.openTPlatform
          let isAnywhere = platform >= 10 && platform <= 14
          if (isAnywhere && typeof v.SignalType == 'string' && !['hotspot', 'eth', 'wifi'].includes(v.SignalType.toLowerCase())) {
            v.CarrierName = v.CarrierName || v.SignalType
          }

          if (typeof v.SignalType == 'string' && v.CarrierName) {
            v.CarrierName = v.CarrierName || ''
            this.slotDetail[v.Index - 1] = {
              carrierName: v.CarrierName || '',
              speed: this.speedInfo[v.Index - 1] && this.speedInfo[v.Index - 1].qualityVal || '0',
              rttInMsec: this.speedInfo[v.Index - 1] && this.speedInfo[v.Index - 1].rttInMsec || ''
            }
          }
        }
        for (let i = 0; i < 6; i++) {
          this.slotDetail[i] = this.slotDetail[i] || { carrierName: '', speed: '0' }
        }
      }
    },
    updateSourceData (type, data) {
      this.sourceDataObj[type.toLowerCase()] = data
    },
    // 获取delay
    getDelay (value, setFlag) {
      if (!setFlag) {
        let val = this.$t('lang.Dynamic')
        return val
      }
      return `${value} s`
    },
    // 从数据库获取源的历史数据
    async getSourceData (startTime, endTime, peerId, clickOpenDetail) {
      if (!startTime) return
      let params = {
        peerId: peerId || this.$route.query.peerId,
        startTime: String(startTime),
        endTime: String(endTime || startTime)
      }
      let data = {}
      let url = '/ccp/tvucc-device/geoRecord/listGeoRecord'

      let collect = sessionStorage.getItem('collect')
      if (collect && Tool.isJSON(collect) && JSON.parse(collect).isSelect && this.$route.query.timeType != '0') {
        url = '/ccp/tvucc-device/favorite/listGeoRecordFavorite'
      }
      if (clickOpenDetail) url = '/ccp/tvucc-device/geoRecord/listGeoRecord'
      await this.axios
        .post(url, params, {
          headers: { 'Content-Type': 'application/json;charset=UTF-8' }
        })
        .then((res) => {
          if (res.data.errorCode == '0x0' && res.data.result) {
            data = endTime ? res.data.result : res.data.result[0]
            if (endTime) this.allMarkData = res.data.result
          }
        })
      return data
    },
    getThumbnail (item, timestamp) {
      let _url = ''
      const blobUrl = this.$store.state.common.baseUrl.blobUrl
      if (!item) {
        return _url
      }
      const status = item.status
      const header = window.location.protocol.includes('https') ? 'https://' : 'http://'
      const isCompressThumbnail = JSON.parse(localStorage.getItem('newCCInitConfig')).urlInfo.isCompressThumbnail
      if (status === '2' || status == '1') {
        _url = `${header}${blobUrl}/${item.peerId && item.peerId.toUpperCase()}/${timestamp}?`
        _url = isCompressThumbnail == '0' ? `${_url}type=original&app=cc&${Math.random()}&tl=60` : `${_url}${Math.random()}&app=cc&tl=60`
      }
      return _url
    },
    // 存储数据到数据库
    async saveSourceData (mark) {
      let signalStrengthVal = ''
      if (this.iconType == 'red') {
        signalStrengthVal = '2'
      } else if (this.iconType == 'orange') {
        signalStrengthVal = '3'
      }
      let slotinfo = []
      this.sourceDataObj.slotinfo && this.sourceDataObj.slotinfo.AdapterModels.map((v) => {
        slotinfo.push(v.Status)
      })
      let QualityMeasureOnGUI = this.sourceDataObj.receiverdata.QualityMeasureOnGUI
      if (QualityMeasureOnGUI === 0) QualityMeasureOnGUI = String(QualityMeasureOnGUI)
      let sourceData = {
        peerId: this.$route.query.peerId,
        bitrate: (this.sourceDataObj.setinfo.CurrentSetting.Bitrate && String(this.sourceDataObj.setinfo.CurrentSetting.Bitrate)) || '',
        longitude: mark[1],
        latitude: mark[0],
        realTimeParams: JSON.stringify({
          QualityMeasureOnGUI: QualityMeasureOnGUI || '',
          type: this.sourceDataObj.currentrinfo.CurrentTInputType.ReadableName,
          format: this.sourceDataObj.currentrinfo.CurrentTFormat.ReadableName,
          delay: this.getDelay(
            (this.sourceDataObj.setinfo.CurrentSetting.Delay / 1000).toFixed(1),
            this.sourceDataObj.setinfo.CurrentTCanSetDelay
          ),
          error: this.sourceDataObj.receiverdata.ErrorRateLastSec,
          quality: this.sourceDataObj.receiverdata.ReSendRateLastSec,
          slotInfo: slotinfo,
          img: this.getThumbnail(
            this.currentSource,
            Math.round(mark[3] / 1000)
          ),
          voiceInfo: this.sourceDataObj.volumechart,
          signalStrength: signalStrengthVal,
          status: this.currentSource.status,
          slotDetail: this.slotDetail
        }),
        timestamp: mark[3]
      }

      if (!sourceData.peerId || !sourceData.longitude || !sourceData.latitude || !sourceData.timestamp) return
      await this.axios.post('/ccp/tvucc-device/geoRecord/saveGeoRecord', sourceData, {
        headers: { 'Content-Type': 'application/json;charset=UTF-8' }
      }).then((res) => {
        if (res.data.errorCode == '0x0') {
          let markType = 'add'
          for (let val of this.allMarkData) {
            if (val.timestamp == sourceData.timestamp) {
              val = sourceData
              markType = 'edit'
              break
            }
          }
          // 如果找不到数据就添加
          if (markType == 'add') {
            this.allMarkData.push(sourceData)
          }
          return true
        }
        return false
      }).catch(() => {
        return false
      })
    },

    // 保存手动打点
    async savePoint (mark, formData, pos) {
      let slotinfo = []
      this.sourceDataObj.slotinfo &&
        this.sourceDataObj.slotinfo.AdapterModels.map((v) => {
          slotinfo.push(v.Status)
        })
      let QualityMeasureOnGUI = this.sourceDataObj.receiverdata.QualityMeasureOnGUI
      if (QualityMeasureOnGUI === 0) {
        QualityMeasureOnGUI = String(QualityMeasureOnGUI)
      }
      let sourceData = {
        peerId: this.$route.query.peerId,
        bitrate: (mark.bitrate != undefined && String(mark.bitrate)) || (this.sourceDataObj.setinfo.CurrentSetting.Bitrate && String(this.sourceDataObj.setinfo.CurrentSetting.Bitrate)) || '',
        longitude: (mark.longitude != undefined && String(mark.longitude)) || pos.longitude.toString() || '',
        latitude: (mark.latitude != undefined && String(mark.latitude)) || pos.latitude.toString() || '',
        realTimeParams: JSON.stringify({
          QualityMeasureOnGUI: QualityMeasureOnGUI || '',
          type: this.sourceDataObj.currentrinfo.CurrentTInputType.ReadableName,
          format: this.sourceDataObj.currentrinfo.CurrentTFormat.ReadableName,
          delay: this.getDelay(
            (this.sourceDataObj.setinfo.CurrentSetting.Delay / 1000).toFixed(1),
            this.sourceDataObj.setinfo.CurrentTCanSetDelay
          ),
          error: this.sourceDataObj.receiverdata.ErrorRateLastSec,
          quality: this.sourceDataObj.receiverdata.ReSendRateLastSec,
          slotInfo: slotinfo,
          img: this.getThumbnail(
            this.currentSource,
            Math.round(mark.timestamp / 1000)
          ),
          signalStrength: Object.prototype.hasOwnProperty.call(mark, 'realTimeParams') && JSON.parse(mark.realTimeParams).signalStrength,
          voiceInfo: this.sourceDataObj.volumechart,
          status: this.currentSource.status
        }),
        signalStrength: (formData.signalStrength && formData.signalStrength.trim()) || '',
        address: formData.address.trim() || '',
        remark: formData.remark.trim() || '',
        timestamp: (mark.timestamp != undefined && String(mark.timestamp)) || this.saveTime,
        slotDetail: this.slotDetail
      }
      if (!sourceData.peerId || !sourceData.longitude || !sourceData.latitude || !sourceData.timestamp) return
      if (sourceData.realTimeParams && sourceData.realTimeParams.length >= 512) return
      await this.axios.post('/ccp/tvucc-device/geoRecord/saveGeoRecord', sourceData, {
        headers: { 'Content-Type': 'application/json;charset=UTF-8' }
      }).then(async (res) => {
        if (res.data.errorCode == '0x0') {
          // 更新本地的allMarkdata
          let markType = 'add'
          for (let val of this.allMarkData) {
            if (val.timestamp == sourceData.timestamp) {
              val = sourceData
              markType = 'edit'
              break
            }
          }
          // 如果找不到数据就添加
          if (markType == 'add') {
            this.allMarkData.push(sourceData)
          }
          // 保存后设置地图标志颜色
          if (mark && Object.prototype.hasOwnProperty.call(formData, 'signalStrength')) {
            let point
            if (mark.longitude && mark.latitude) {
              point = new BMap.Point(mark.longitude, mark.latitude)
            } else {
              point = new BMap.Point(pos.longitude.toString(), pos.latitude.toString())
            }
            let icon = new BMap.Icon(
              require('@/assets/img/map/map_point.svg'),
              new BMap.Size(4, 4)
            )

            // 选择Good
            if (formData.signalStrength === '0') {
              icon = new BMap.Icon(
                require('@/assets/img/map/location_green.svg'),
                new BMap.Size(32, 32)
              )

              if (this.manualGreen.length === 0) {
                point.timestamp = mark.timestamp
                this.manualGreen.push(point)
              } else {
                for (let i = 0; i < this.manualGreen.length; i++) {
                  if (this.manualGreen[i].timestamp !== mark.timestamp) {
                    point.timestamp = mark.timestamp
                    this.manualGreen.push(point)
                  }
                }
              }
            } else if (formData.signalStrength === '1') {
              // 选择Bad
              icon = new BMap.Icon(
                require('@/assets/img/map/location_yellow.svg'),
                new BMap.Size(32, 32)
              )

              if (this.manualOrange.length === 0) {
                point.timestamp = mark.timestamp
                this.manualOrange.push(point)
              } else {
                for (let i = 0; i < this.manualOrange.length; i++) {
                  if (this.manualOrange[i].timestamp !== mark.timestamp) {
                    point.timestamp = mark.timestamp
                    this.manualOrange.push(point)
                  }
                }
              }
            } else if (this.singnalString == 'very bad') {
              icon = new BMap.Icon(
                require('@/assets/img/map/location_red.svg'),
                new BMap.Size(32, 32)
              )
            } else if (this.singnalString == 'worse') {
              icon = new BMap.Icon(
                require('@/assets/img/map/location_orange.svg'),
                new BMap.Size(32, 32)
              )
            }
            let that = this
            let markers = new BMap.Marker(point, { icon: icon }) // 创建标注
            let infoModal = null
            this.map.addOverlay(markers)
            markers.addEventListener('click', async function (e) {
              let position = {
                y: e.point.lng,
                x: e.point.lat
              }

              let timestamp = null
              if (that.allMarkData.length !== 0) {
                timestamp = that.getTimestamp(
                  position,
                  that.allMarkData,
                  true
                )
                if (!timestamp) {
                  timestamp = that.getTimestamp(
                    position,
                    that.allPositionData
                  )
                }
              } else {
                timestamp = that.getTimestamp(position, that.allPositionData)
              }
              let strInfo = await that.openSourceDetail(timestamp, position)
              infoModal = new window.BMap.InfoWindow(strInfo)
              this.openInfoWindow(infoModal)
              setTimeout(() => {
                // 系统自动打点设置删除按钮不显示
                that.hideDelete()
              }, 0)
            })

            $('.BMap_pop').hide()
          }
          // 如果是历史数据那么重新加载历史线条
          return true
        }
        return false
      }).catch(() => {
        return false
      })
      await this.getAllMaker()
      if (this.historyFlag) {
        this.getHistory()
      } else {
        this.makeLine()
      }
      if (Object.keys(mark).length !== 0) {
        let centerPoint = new BMap.Point(mark.longitude, mark.latitude)
        this.map.setCenter(centerPoint)
      } else {
        let centerPoint = new BMap.Point(pos.longitude, pos.latitude)
        this.map.setCenter(centerPoint)
      }
    },
    // 处理多路追踪
    handleMultiple (multiple) {
      let devices = multiple || this.$route.query.multiple
      let peerIds = []
      devices && devices.split(',').map(v => {
        peerIds.push(v.split('-')[1])
      })
      if (this.$createWebsocket.pageRequest) {
        this.$createWebsocket.pageRequest('stop', ['getLocationByIps_hm']) // 停止websocket
      }
      // 获取多个设备的实时位置
      this.getDevicePosition(peerIds)
      this.stopDeviceWebsocket()
    },
    // stop websocket
    stopDeviceWebsocket () {
      if (this.$createWebsocket.pageRWebsocket) {
        this.$createWebsocket.pageRWebsocket('stop', [
          'VolumeChart',
          'slotInfo',
          'receiverData',
          'currentRInfo',
          'setInfo'
        ])
      }
      if (this.$createWebsocket.pageRequest) {
        this.$createWebsocket.pageRequest('stop', ['detailInfo']) // 停止websocket
      }
    },
    // 获取多个设备的实时位置
    getDevicePosition (peerIds) {
      this.pageRequest.send({
        message: {
          type: 'getLocationByIps',
          module: 'getLocationByIps_hm',
          data: { peerIds: peerIds.join(','), peerIps: '', mapType: this.mapType }
        },
        time: 1000,
        key: 'getLocationByIps_hm',
        success: res => {
          if (!res.result || !res.result.errorCode != '0x0') return
          let positionArr = JSON.parse(res.result.result).data
          this.setDevicePosition(positionArr)
        }
      })
    },
    // 设置设备位置
    setDevicePosition (positionArr) {
      if (this.isDeviceWindow) return
      // 清除上次的位置
      this.allPosition.map(v => {
        this.map.removeOverlay(v)
      })
      this.allPosition = []
      // 重新设置位置
      positionArr.map((v, i) => {
        let pt = new BMap.Point(Number(v.location.split(',')[1]), Number(v.location.split(',')[0]))
        let icon = new BMap.Icon(
          require('@/assets/img/map/multi-tracking.svg'),
          new BMap.Size(48, 48)
        )
        let marker = new BMap.Marker(pt, { icon: icon })
        this.map.addOverlay(marker)

        let name = this.allDeviceLivePeerId[v.peerId.toLowerCase()].name
        var label = new BMap.Label(`<div class='device_icon_name ellipsis' title='${name}'>${name}</div>`, { offset: new BMap.Size(-29, 39) })
        label.setStyle({ // 给label设置样式，任意的CSS都是可以的
          color: '#000',
          fontSize: '12px',
          border: 'none',
          textAlign: 'center',
          lineHeight: '10px',
          background: 'rgb(255, 255, 255)',
          cursor: 'pointer',
          padding: '5px 10px'
        })
        let title = `${this.$t('lang.equipmentIdentity')}${v.peerId.toLowerCase()}\n${this.$t('lang.equipmentName')}${name}`
        marker.setTitle(title)
        marker.setLabel(label)
        this.allPosition.push(marker)

        // 点击
        this.clickDeviceMarker(marker, v)
      })
    },
    // 单次查询设备信息
    singleQueryInfo (key, categoryId, operationType, time, livePeerId) {
      return new Promise((resolve, reject) => {
        this.sendMsgWebsocket(key, categoryId, operationType, time, livePeerId, resolve) // 获取slot信息
      })
    },
    // 点击多路追踪中的设备
    clickDeviceMarker (marker, item) {
      let that = this
      marker.addEventListener('click', async function (e) {
        if (!that.deviceTimeFlag) return
        that.deviceTimeFlag = false
        that.loading = true
        // 多路设备模式下，查询点击设备的信息
        let { livePeerId } = that.allDeviceLivePeerId[item.peerId.toLowerCase()]
        that.openTPlatform = that.allDeviceLivePeerId[item.peerId.toLowerCase()].platform
        item.name = that.allDeviceLivePeerId[item.peerId.toLowerCase()].name
        let source = {
          status: 1,
          type: 't'
        }
        let sourceData = {
          deviceName: item.name,
          peerId: '',
          bitrate: '',
          longitude: parseInt(item.location.split(',')[1] * 100000000) / 100000000,
          latitude: parseInt(item.location.split(',')[0] * 100000000) / 100000000,
          realTimeParams: JSON.stringify({
            QualityMeasureOnGUI: '',
            type: '',
            format: '',
            delay: '',
            error: '',
            quality: '',
            slotInfo: {},
            img: '',
            voiceInfo: {},
            signalStrength: '',
            status: '1',
            slotDetail: [[], [], [], [], [], []]
          }),
          timestamp: ''
        }
        if (livePeerId) {
          source.status = 2
          that.isDeviceWindow = true
          let promiseArr = []
          promiseArr.push(new Promise((resolve, reject) => { // 从T上拿slot信息
            that.getSlotBitrate(item.peerId, 1, resolve)
          }))
          promiseArr.push(that.singleQueryInfo('VolumeChart', '2152867840', '200', 1, livePeerId)) // 获取音量信息
          promiseArr.push(that.singleQueryInfo('SlotInfo', '2152865792', '101', 1, livePeerId)) // 获取slot信息
          promiseArr.push(that.singleQueryInfo('CurrentRInfo', '2152857600', '102', 1, livePeerId)) // 获取type and format信息
          promiseArr.push(that.singleQueryInfo('SetInfo', '2152866048', '101', 1, livePeerId)) // 获取bitrate and delay信息
          promiseArr.push(that.singleQueryInfo('ReceiverData', '2152857600', '101', 1, livePeerId)) // 获取err and quality信息
          let isOpen = false // 是否打开弹窗
          Promise.all(promiseArr).then((values) => {
            that.handleWindowInfo(item, this, source, sourceData)
            that.loading = false
            that.openTPlatform = null
            isOpen = true
            that.deviceTimeFlag = true
          }).catch(() => {
            that.handleWindowInfo(item, this, source, sourceData)
            that.loading = false
            that.openTPlatform = null
            isOpen = true
            that.deviceTimeFlag = true
          })
          setTimeout(() => {
            that.loading = false
            that.openTPlatform = null
            that.isDeviceWindow = isOpen
            that.deviceTimeFlag = true
          }, 3000)
        } else {
          setTimeout(() => {
            that.deviceTimeFlag = true
          }, 1500)
          setTimeout(() => {
            that.isDeviceWindow = true
          })
          that.isDeviceWindow = true
          let strInfo = await that.openSourceDetail(new Date(), {}, sourceData)

          let info = new window.BMap.InfoWindow(strInfo)
          this.openInfoWindow(info)
          that.loading = false
          that.openTPlatform = null
          info.addEventListener('close', function () {
            that.isDeviceWindow = false
          })
        }
      })
    },
    // 弹窗中的消息从websocket中获取
    async handleWindowInfo (item, that, source, sourceData) {
      let QualityMeasureOnGUI = this.sourceDataObj.receiverdata.QualityMeasureOnGUI
      if (QualityMeasureOnGUI === 0) {
        QualityMeasureOnGUI = String(QualityMeasureOnGUI)
      }
      let slotinfo = []
      this.sourceDataObj.slotinfo &&
      this.sourceDataObj.slotinfo.AdapterModels.map((v) => {
        slotinfo.push(v.Status)
      })
      sourceData = {
        deviceName: item.name,
        peerId: item.peerId,
        bitrate: (this.sourceDataObj.setinfo.CurrentSetting.Bitrate && String(this.sourceDataObj.setinfo.CurrentSetting.Bitrate)) || '',
        longitude: parseInt(item.location.split(',')[1] * 100000000) / 100000000,
        latitude: parseInt(item.location.split(',')[0] * 100000000) / 100000000,
        realTimeParams: JSON.stringify({
          QualityMeasureOnGUI: QualityMeasureOnGUI || '',
          type: this.sourceDataObj.currentrinfo.CurrentTInputType.ReadableName,
          format: this.sourceDataObj.currentrinfo.CurrentTFormat.ReadableName,
          delay: this.getDelay(
            (this.sourceDataObj.setinfo.CurrentSetting.Delay / 1000).toFixed(1),
            this.sourceDataObj.setinfo.CurrentTCanSetDelay
          ),
          error: this.sourceDataObj.receiverdata.ErrorRateLastSec,
          quality: this.sourceDataObj.receiverdata.ReSendRateLastSec,
          slotInfo: slotinfo,
          img: this.getThumbnail(source),
          voiceInfo: this.sourceDataObj.volumechart,
          signalStrength: '',
          status: '2',
          slotDetail: this.slotDetail
        }),
        timestamp: ''
      }
      let strInfo = await this.openSourceDetail('', {}, sourceData)
      let info = new window.BMap.InfoWindow(strInfo)
      that.openInfoWindow(info)
      info.addEventListener('close', function () {
        this.isDeviceWindow = false
      })
      setTimeout(() => {
        this.isDeviceWindow = true
      })
    },
    // 存储所有maker点
    async getAllMaker (val) {
      if (val) {
        if (val.timeType === '0') {
          this.params.starttime = this.interval
          this.params.mapType = 'baidu';
          (this.params.endtime || this.params.endtime === 0) && delete this.params.endtime
        }
      } else {
        if (this.$route.query.timeType === '0') {
          this.params.starttime = this.interval
          this.params.mapType = 'baidu';
          (this.params.endtime || this.params.endtime === 0) && delete this.params.endtime
        }
      }
      await this.axios.get(`${this.url}/Out/getGPSTrackingServlet`, { params: this.params },
        { headers: { 'Content-Type': 'application/json;charset=UTF-8' } }).then(async (res) => {
        if (!res.data || (typeof res.data == 'string' && !res.data.trim())) return
        if (this.$route.query.timeType === '0') {
          // 如果返回了手动设置的点，比较返回时间与当前时间是否相差超过一定范围
          let incrementalRangeTime = localStorage.getItem(
            'incrementalRangeTime'
          )
          let manualIncrementTime = localStorage.getItem(
            'manualIncrementTime'
          )
          let currentGps = res.data.trim().split('\r\n')
          let currentTime = new Date().getTime()
          let removeGps = []
          currentGps.map((v, i) => {
            let currentData = v.split(',')
            // 返回时间与当前时间相差超过一定范围
            if (Math.abs(currentTime - currentData[3]) > incrementalRangeTime) {
              // 当前时间-计算后时间的差值是否在一定范围内，如果不在，则去掉这个点
              if (Math.abs(currentTime - Math.abs(currentData[3] - manualIncrementTime)) > incrementalRangeTime) {
                removeGps.push(i)
              } else {
                // 在则更改这个点的时间戳
                currentData[3] = currentData[3] - manualIncrementTime
              }
            }
          })
          let removeNum = 0
          removeGps.map((v) => {
            currentGps.splice(v - removeNum, 1)
            removeNum++
          })
          res.data = currentGps.join('\r\n')
        }
        this.firstData = res.data.trim().split('\r\n')
        // 间隔时间为10秒以上的才显示点
        let gpsPoints = [] // gps返回的点
        let filterGpsArr = []
        this.firstData.map((v) => {
          if (!v) return
          let gpsPoint = v.split(',')
          gpsPoints.push({
            longitude: gpsPoint[1],
            latitude: gpsPoint[0],
            timestamp: gpsPoint[3]
          })
          if (!filterGpsArr.length) filterGpsArr.push(v)
          if (v.split(',')[3] - filterGpsArr[filterGpsArr.length - 1].split(',')[3] >= this.spaceTime) {
            filterGpsArr.push(v)
          }
        })
        res.data = filterGpsArr.join('\r\n')

        // 合并geo坐标和标记坐标，以标记坐标为基础，两个点之间的时间戳
        let filterMarkPoints = []
        let filterGeoPoints = []
        let secondFilterGeoPoints = []
        let timestampArr = []
        this.allMarkData.sort((m, n) => {
          return m.timestamp - n.timestamp
        })
        gpsPoints.sort((m, n) => {
          return m.timestamp - n.timestamp
        })
        // 筛选marker点间隔一定时间
        this.allMarkData.map((v) => {
          if (!filterMarkPoints.length) {
            timestampArr.push(String(v.timestamp))
            filterMarkPoints.push(v)
          }
          if (v.timestamp - filterMarkPoints[filterMarkPoints.length - 1].timestamp >= this.spaceTime) {
            timestampArr.push(String(v.timestamp))
            filterMarkPoints.push(v)
          }
        })
        filterMarkPoints.map((v, i) => {
          gpsPoints.map((item) => {
            if (!filterGeoPoints.length && !timestampArr.includes(String(v.timestamp))) {
              timestampArr.push(String(item.timestamp))
              filterGeoPoints.push(item)
            }
            // 当前geo点与当前marker点的时间戳差值
            let b = Math.abs(item.timestamp - v.timestamp)
            // 当前geo点与下一个marker点的时间戳差值
            let c = (filterMarkPoints[i + 1] && filterMarkPoints[i + 1].timestamp - item.timestamp) || false
            if ((b >= this.spaceTime && c !== false && c >= this.spaceTime) || (c === false && b >= this.spaceTime)) {
              if (!timestampArr.includes(String(item.timestamp))) {
                filterGeoPoints.push(item)
                timestampArr.push(String(item.timestamp))
              }
            }
          })
        })
        filterGeoPoints.map((v) => {
          if (!secondFilterGeoPoints.length) {
            secondFilterGeoPoints.push(v)
          }
          if (v.timestamp - secondFilterGeoPoints[secondFilterGeoPoints.length - 1].timestamp >= this.spaceTime) {
            secondFilterGeoPoints.push(v)
          }
        })
        let concatArr = filterGeoPoints
          .concat(filterMarkPoints)
          .sort((m, n) => {
            return m.timestamp - n.timestamp
          })
        this.allPositionData = []
        concatArr.map((v) => {
          if (!this.allPositionData.length) {
            this.allPositionData.push(v)
            return
          }
          let lastPoint = this.allPositionData[
            this.allPositionData.length - 1
          ]
          if (v.timestamp - lastPoint.timestamp >= this.spaceTime || v.id) {
            this.allPositionData.push(v)
          }
        })
        // mark点为空时
        if (!filterMarkPoints.length && gpsPoints.length) {
          let gpsPointsFilter = []
          for (let i = 0; i < gpsPoints.length; i++) {
            let v = gpsPoints[i]
            if (!gpsPointsFilter.length) {
              gpsPointsFilter.push(v)
              continue
            }
            if (!v.timestamp || !gpsPointsFilter[gpsPointsFilter.length - 1].timestamp) {
              continue
            }
            if (v.timestamp - gpsPointsFilter[gpsPointsFilter.length - 1].timestamp >= this.spaceTime) {
              gpsPointsFilter.push(v)
            }
          }
          this.allPositionData = gpsPointsFilter
        }
        let infos = JSON.parse(JSON.stringify(this.firstData))
        var points = []
        this.timestamps = []
        for (let i in infos) {
          var ainfo = infos[i].split(',')
          points.push(new BMap.Point(Number(ainfo[1]), Number(ainfo[0])))
          this.timestamps[i] = ainfo[3] + '_' + 0
        }

        if (this.currentSource.status == 2 && this.$route.query.timeType == '0') {
          // 如果源不在线了，停止保存
          if (res.data && infos.length) {
            await this.saveSourceData(infos[infos.length - 1].split(','))
          }
        }
      }).catch(() => {})
    },
    // 更新历史数据
    getHistory (maker) {
      // 首先清除所有覆盖物
      this.map.clearOverlays()
      let pointList = []
      let infoModal = []
      for (let i = 0; i < this.allPositionData.length; i++) {
        let makerPoint = this.allPositionData[i]
        let point = new BMap.Point(makerPoint.longitude, makerPoint.latitude)
        pointList[i] = point
        let icon = new BMap.Icon(
          require('@/assets/img/map/map_point.svg'),
          new BMap.Size(4, 4)
        )
        let markers = null

        // 手动打点绿色
        if (makerPoint.signalStrength == '0') {
          icon = new BMap.Icon(
            require('@/assets/img/map/location_green.svg'),
            new BMap.Size(32, 32)
          )
        } else if (makerPoint.signalStrength == '1') {
          // 手动打点黄色
          icon = new BMap.Icon(
            require('@/assets/img/map/location_yellow.svg'),
            new BMap.Size(32, 32)
          )
        } else if (
          Object.prototype.hasOwnProperty.call(makerPoint, 'realTimeParams') &&
          JSON.parse(makerPoint.realTimeParams).signalStrength === '2'
        ) {
          // 系统自动打点红色
          icon = new BMap.Icon(
            require('@/assets/img/map/location_red.svg'),
            new BMap.Size(32, 32)
          )
        } else if (
          Object.prototype.hasOwnProperty.call(makerPoint, 'realTimeParams') &&
          JSON.parse(makerPoint.realTimeParams).signalStrength === '3'
        ) {
          // 系统自动打点橙色
          icon = new BMap.Icon(
            require('@/assets/img/map/location_orange.svg'),
            new BMap.Size(32, 32)
          )
        } else {
          // 普通点
          icon = new BMap.Icon(
            require('@/assets/img/map/map_point.svg'),
            new BMap.Size(4, 4)
          )
        }
        markers = new BMap.Marker(point, { icon: icon }) // 创建标注
        this.map.addOverlay(markers)
        // 终点
        if (i == this.allPositionData.length - 1) {
          let icon = null
          icon = new BMap.Icon(
            require('@/assets/img/map/end_point.svg'),
            new BMap.Size(32, 32)
          )

          markers = new BMap.Marker(point, { icon: icon }) // 创建标注
          this.map.addOverlay(markers)
        }
        if (i === 0) {
          let icon = new BMap.Icon(
            require('@/assets/img/map/start_point.svg'),
            new BMap.Size(32, 32)
          )
          markers = new BMap.Marker(point, { icon: icon }) // 创建标注
          this.map.addOverlay(markers)
        }

        let that = this
        markers.addEventListener('click', async function (e) {
          let position = {
            y: e.point.lng,
            x: e.point.lat
          }
          let timestamp = null
          if (that.allMarkData.length !== 0) {
            timestamp = that.getTimestamp(position, that.allMarkData, true)
            if (!timestamp) {
              timestamp = that.getTimestamp(position, that.allPositionData)
            }
          } else {
            timestamp = that.getTimestamp(position, that.allPositionData)
          }
          let strInfo = await that.openSourceDetail(timestamp, position)

          infoModal[i] = new window.BMap.InfoWindow(strInfo)
          await this.openInfoWindow(infoModal[i])

          setTimeout(() => {
            // 系统自动打点设置删除按钮不显示
            that.hideDelete()
          }, 3)
        })
      }

      // // 黄色的点
      if (this.manualOrange.length !== 0) {
        let manualPointOrange = []
        for (let i in this.manualOrange) {
          let point = this.manualOrange[i]
          let icon = new BMap.Icon(
            require('@/assets/img/map/location_yellow.svg'),
            new BMap.Size(32, 32)
          )
          manualPointOrange[i] = new BMap.Marker(point, { icon: icon }) // 创建标注

          this.map.addOverlay(manualPointOrange[i])
        }
      }

      // 绿色的点
      if (this.manualGreen !== 0) {
        let manualPointGreen = []
        for (let i in this.manualGreen) {
          let point = this.manualGreen[i]
          let icon = new BMap.Icon(
            require('@/assets/img/map/location_green.svg'),
            new BMap.Size(32, 32)
          )
          manualPointGreen[i] = new BMap.Marker(point, { icon: icon }) // 创建标注

          this.map.addOverlay(manualPointGreen[i])
          manualPointGreen[i].addEventListener('click', async function (e) {
            let position = {
              y: e.point.lng,
              x: e.point.lat
            }

            let timestamp = null
            if (that.allMarkData.length !== 0) {
              timestamp = that.getTimestamp(position, that.allMarkData, true)
              if (!timestamp) {
                timestamp = that.getTimestamp(position, that.allPositionData)
              }
            } else {
              timestamp = that.getTimestamp(position, that.allPositionData)
            }

            let strInfo = await that.openSourceDetail(timestamp, position)
            infoModal[i] = new window.BMap.InfoWindow(strInfo)
            this.openInfoWindow(infoModal[i]) // 悬浮监听提示方法
            setTimeout(() => {
              // 系统自动打点设置删除按钮不显示
              that.hideDelete()
            }, 10)
          })
        }
      }

      var sy = new BMap.Symbol('BMap_Symbol_SHAPE_BACKWARD_OPEN_ARROW', {
        scale: 0.6, // 图标缩放大小
        strokeColor: '#fff', // 设置矢量图标的线填充颜色
        strokeWeight: '2' // 设置线宽
      })
      var icons = new BMap.IconSequence(sy, '300px', '300px')
      var polyline = new BMap.Polyline(pointList, {
        strokeWeight: '4', // 折线的宽度，以像素为单位
        strokeOpacity: 1, // 折线的透明度，取值范围0 - 1
        strokeColor: '#18A45B', // 折线颜色
        icons: [icons]
      })
      let that = this
      polyline.addEventListener('click', async function (e) {
        let position = {
          y: e.point.lng,
          x: e.point.lat
        }

        let timestamp = null
        if (that.allMarkData.length !== 0) {
          timestamp = that.getTimestamp(position, that.allMarkData, true)
          if (!timestamp) {
            timestamp = that.getTimestamp(position, that.allPositionData)
          }
        } else {
          timestamp = that.getTimestamp(position, that.allPositionData)
        }
        var point = new BMap.Point(e.point.lng, e.point.lat)
        let strInfo = await that.openSourceDetail(timestamp, position)

        var infoModal = new window.BMap.InfoWindow(strInfo)
        await this.map.openInfoWindow(infoModal, point)

        setTimeout(() => {
          // 系统自动打点设置删除按钮不显示
          that.hideDelete()
        }, 3)
      })
      that.map.addOverlay(polyline)
      var viewPort = this.map.getViewport(pointList, {
        zoomFactor: 1
      })
      var zoomNum = 0
      zoomNum = viewPort.zoom
      this.map.setZoom(zoomNum)

      if (maker) {
        let centerPoint = new BMap.Point(maker.longitude, maker.latitude)
        this.map.setCenter(centerPoint)
      } else {
        this.map.setCenter(pointList[pointList.length - 1])
      }
    },

    // 比特率的颜色
    showBitClass (sourceData, realTimeParams) {
      let _str = ''
      const realBit = realTimeParams.QualityMeasureOnGUI
      const setBit = sourceData.bitrate
      if (realBit / setBit < 0.6) {
        _str = 'orange'
      }
      if (realBit / setBit < 0.4) {
        _str = 'flashRed'
      }
      return _str
    },
    async openSourceDetail (time, position, sourceData) {
      let clickOpenDetail = false
      if (sourceData === undefined) {
        clickOpenDetail = true
        this.gpsDuration = localStorage.getItem('gpsDuration') || 120
        sourceData = (time && (await this.getSourceData(time, undefined, undefined, clickOpenDetail))) || {}
      }
      this.windowDetail = sourceData
      this.saveTime = time
      let makerPos = {}
      // 对没有信息的点的坐标进行处理
      if (Object.keys(sourceData).length == 0) {
        makerPos.longitude = position.y
        makerPos.latitude = position.x
      } else {
        makerPos.longitude = sourceData.longitude
        makerPos.latitude = sourceData.latitude
        this.signal = sourceData.signalStrength
      }
      let zoom = this.map.getZoom()
      /*
      * 设置中心点
      * let centerPoint = new BMap.Point(makerPos)
      * this.map.setCenter(centerPoint);
      */
      this.map.centerAndZoom(makerPos, zoom)

      this.mapSourceData = sourceData
      // 首先处理接口回显form表单的数据
      let addressValue = !this.mapSourceData.address ? '' : this.mapSourceData.address
      let remark = !this.mapSourceData.remark ? '' : this.mapSourceData.remark
      let signalStrength = !this.mapSourceData.signalStrength ? '' : this.mapSourceData.signalStrength
      // 信号显示
      let singnalValue = ''
      let singnalFormatter = ''
      if (this.mapSourceData.signalStrength && this.mapSourceData.signalStrength !== '') {
        singnalValue = this.mapSourceData.signalStrength === '1' ? 'Bad' : 'Good'
        singnalFormatter = this.mapSourceData.signalStrength === '1' ? this.$t('lang.bad') : this.$t('lang.good')
      } else if (
        Object.prototype.hasOwnProperty.call(this.mapSourceData, 'realTimeParams') &&
        JSON.parse(this.mapSourceData.realTimeParams).signalStrength === '2'
      ) {
        singnalValue = 'very bad'
        singnalFormatter = this.$t('lang.veryBad')
      } else if (
        Object.prototype.hasOwnProperty.call(this.mapSourceData, 'realTimeParams') &&
        JSON.parse(this.mapSourceData.realTimeParams).signalStrength === '3'
      ) {
        singnalValue = 'worse'
        singnalFormatter = this.$t('lang.worse')
      } else {
        singnalValue = 'Good'
        singnalFormatter = this.$t('lang.good')
      }
      this.singnalString = singnalValue

      let realTimeParams = (sourceData.realTimeParams && JSON.parse(sourceData.realTimeParams)) || {}
      /*
        * realTimeParams = {"QualityMeasureOnGUI":3646,"type":"Android","format":"720p59.94","delay":"2.0 s","error":0,"quality":50.6042,"slotInfo":[0,3,0,0,0,0,0,0,0,0],"img":"https://blob.tvunetworks.com/t/AA9EE81EC220D270/1611642895?type=original&app=cc&0.9024190430307988&tl=60","voiceInfo":{"DB":[-27,-27,-40,-40,-40,-40,-40,-40],"RightDb":-27,"LeftDb":-27},"signalStrength":"","status":"2","slotDetail":[{"qualityVal":"0","rttInMsec":"","speed":"0"},{"qualityVal":"0","rttInMsec":"","speed":"0"},{"qualityVal":"4296","rttInMsec":"","carrierName":"4G","speed":"4296"},{"qualityVal":"0","rttInMsec":"","speed":"0"},{"qualityVal":"0","rttInMsec":"","speed":"0"},{"qualityVal":"0","rttInMsec":"","speed":"0"},{"qualityVal":"0","rttInMsec":"","speed":"0"},{"qualityVal":"0","rttInMsec":"","speed":"0"},{"qualityVal":"0","rttInMsec":"","speed":"0"},{"qualityVal":"0","rttInMsec":"","speed":"0"},{"qualityVal":"4296","rttInMsec":"","speed":"4296"},{"qualityVal":"0","rttInMsec":"","speed":"0"},{"qualityVal":"0","rttInMsec":"","speed":"0"}]}
        * 计算比特率总和
        */
      let totalBit = 0
      realTimeParams.slotDetail = realTimeParams.slotDetail || [[], [], [], [], [], []]
      realTimeParams.slotDetail.length && realTimeParams.slotDetail.map(v => {
        totalBit += Number(v.speed || 0)
      })
      let realBit
      if (Object.prototype.hasOwnProperty.call(sourceData, 'bitrate')) {
        /*
          * 如果实时比特率小于等于设置的比特率，显示实时比特率;
          * 如果大于，则显示设置的比特率
          */
        sourceData.bitrate = sourceData.bitrate || 0
        realBit = totalBit <= sourceData.bitrate ? totalBit : sourceData.bitrate
        // 如果大于，单卡的比特率值为，单卡值*设置的值/几张卡的总值
        if (totalBit > sourceData.bitrate) {
          realTimeParams.slotDetail.length && realTimeParams.slotDetail.map(v => {
            v.speed = Math.round(v.speed * sourceData.bitrate / totalBit)
          })
        }
      }
      let formDetail = {
        signalStrength,
        makerPos,
        addressValue,
        remark
      }
      this.formDetail = formDetail
      let slotInfo = realTimeParams.slotInfo || []
      let voiceInfo = realTimeParams.voiceInfo || {}
      let date
      if (time) {
        date = Tool.getDateTime(new Date(Number(time)))
      }
      let strDate = ''
      // 右下角显示的最新编辑时间

      if (Object.keys(this.mapSourceData).length != 0 && !sourceData.deviceName && this.mapSourceData.createTime) {
        let editTime = new Date(this.mapSourceData.createTime) // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
        var Y = editTime.getFullYear() + '.'
        var M = (editTime.getMonth() + 1 < 10 ? '0' + (editTime.getMonth() + 1) : editTime.getMonth() + 1) + '.'
        var D = (editTime.getDate() < 10 ? '0' + editTime.getDate() : editTime.getDate()) + ' '
        var h = (editTime.getHours() < 10 ? '0' + editTime.getHours() : editTime.getHours()) + ':'
        var m = (editTime.getMinutes() < 10 ? '0' + editTime.getMinutes() : editTime.getMinutes()) + ':'
        var s = editTime.getSeconds() < 10 ? '0' + editTime.getSeconds() : editTime.getSeconds()
        strDate = Y + M + D + h + m + s
      }
      let strInfo = `<div class="map-content map_gps clearfix">
        <div class="vedio-msg">
          <img class='img-map' alt='error' onerror=${this.deafultImg}  src=${realTimeParams.img || ''}/>

          <div class="t_thumbnail_view_item_icon liveBg ${realTimeParams.status == 2 ? '' : 'hide'}">
            ${realTimeParams.status == 2 ? this.$t('lang.home_Live') : ''}
          </div>

          <div class="audio_column">
            <div class="audio_scale">
              <p class='audio_scale_num'>
                <span class='scale_12'>+12</span>
              </p>
              <p class='audio_scale_num'>
                <span class=''></span>
              </p>
              <p class='audio_scale_num'>
                <span class=''></span>
              </p>
              <p class='audio_scale_num'>
                <span class='scale_0'>0</span>
              </p>
              <p class='audio_scale_num'>
                <span class=''></span>
              </p>
              <p class='audio_scale_num'>
                <span class=''></span>
              </p>
              <p class='audio_scale_num'>
                <span class='scale_minus_12'>-12</span>
              </p>
              <p class='audio_scale_num'>
                <span class=''></span>
              </p>
              <p class='audio_scale_num'>
                <span class='scale_minus_20'>-20</span>
              </p>
              <p class='audio_scale_num'>
                <span class=''></span>
              </p>
              <p class='audio_scale_num'>
                <span class=''></span>
              </p>
              <p class='audio_scale_num'>
                <span class=''></span>
              </p>
              <p class='audio_scale_num'>
                <span class=''></span>
              </p>
              <p class='audio_scale_num'>
                <span class='scale_minus_40'>-40</span>
              </p>
            </div>
            <div class="audio_col audio_col_one">
              <div class="volume_bar">
                <div class="progress-bar" style="width:${(Number(voiceInfo.LeftDb || -40) + 40) * 1.923}%"></div>
              </div>
              <div class="volume_bar">
                <div class="progress-bar" style="width:${(Number(voiceInfo.RightDb || -40) + 40) * 1.923}%"></div>
              </div>
            </div>
          </div>

          <div class="date_box">
            <i class="el-icon-date"></i>
            <span>${date ? date.year + '.' + date.month + '.' + date.day + ' ' + date.hour + ':' + date.min + ':' + date.s : ''}</span>
          </div>

          <div class="device_detail">
            <p>
              ${this.$t('lang.type')}: ${realTimeParams.type || ''}
            </p>
            <p>
              ${this.$t('lang.format')}: ${realTimeParams.format || ''}
            </p>
            <p>${this.$t('lang.bitrate')}:
              <span class=${this.showBitClass(sourceData, realTimeParams)}>
                ${realBit || '0'}
              </span>
              /
              ${sourceData.bitrate || ''} kbps
            </p>
            <p>${this.$t('lang.delay')}: ${realTimeParams.delay || ''}</p>
            <p>${this.$t('lang.error')}: ${realTimeParams.error || '0'}%</p>
            <p>${this.$t('lang.quality')}: ${realTimeParams.quality || '0'}%</p>
            <div class="slot">
                <ul class="card-info row">
                  <li class=""><i class='${slotInfo[0] == 1 ? 'one' : ''} ${slotInfo[0] == 2 ? 'two' : ''} ${slotInfo[0] == 3 ? 'three' : ''}'></i></li>
                  <li class=""><i class='${slotInfo[1] == 1 ? 'one' : ''} ${slotInfo[1] == 2 ? 'two' : ''} ${slotInfo[1] == 3 ? 'three' : ''}'></i></li>
                  <li class=""><i class='${slotInfo[2] == 1 ? 'one' : ''} ${slotInfo[2] == 2 ? 'two' : ''} ${slotInfo[2] == 3 ? 'three' : ''}'></i></li>
                  <li class=""><i class='${slotInfo[3] == 1 ? 'one' : ''} ${slotInfo[3] == 2 ? 'two' : ''} ${slotInfo[3] == 3 ? 'three' : ''}'></i></li>
                  <li class=""><i class='${slotInfo[4] == 1 ? 'one' : ''} ${slotInfo[4] == 2 ? 'two' : ''} ${slotInfo[4] == 3 ? 'three' : ''}'></i></li>
                  <li class=""><i class='${slotInfo[5] == 1 ? 'one' : ''} ${slotInfo[5] == 2 ? 'two' : ''} ${slotInfo[5] == 3 ? 'three' : ''}'></i></li>
                </ul>
            </div>
            <div>
              <i class='iconfont icon-zoomin icon_operation' onClick='extendSourceDetail()'></i>
            </div>
          </div>
          <div class='extend_detail hide'>
            <div class='source_detail clearfix'>
              <p>
                ${this.$t('lang.type')}: ${realTimeParams.type || ''}
              </p>
              <p>
                ${this.$t('lang.format')}: ${realTimeParams.format || ''}
              </p>
              <p>${this.$t('lang.bitrate')}:
                <span class=${this.showBitClass(sourceData, realTimeParams)}>
                  ${realBit || '0'}
                </span>
                /
                ${sourceData.bitrate || ''} kbps
              </p>
              <p>${this.$t('lang.delay')}: ${realTimeParams.delay || ''}</p>
              <p>${this.$t('lang.error')}: ${realTimeParams.error || '0'}%</p>
              <p>${this.$t('lang.quality')}: ${realTimeParams.quality || '0'}%</p>
            </div>
            <div class='slot slot_detail'>
              <p class='slot_title'>
                <span class='slot_item'>Slot</span>
                <span class='carrier_name'>Carrier</span>
                <span class='slot_bit'>Bitrate (kbps)</span>
                <span>RTT(ms)</span>
              </p>
              <p>
                <span class='slot_item'>1</span>
                <i class='${slotInfo[0] == 1 ? 'one' : ''} ${slotInfo[0] == 2 ? 'two' : ''} ${slotInfo[0] == 3 ? 'three' : ''}'></i>
                <span class='carrier_name ellipsis'>${realTimeParams.slotDetail && realTimeParams.slotDetail[0].carrierName || this.$t('lang.noDevice')}</span>
                <span class='slot_bit'>${realTimeParams.slotDetail && realTimeParams.slotDetail[0].speed || 0} kbps</span>
                <span class=''>${realTimeParams.slotDetail && realTimeParams.slotDetail[0].rttInMsec || ''}</span>
              </p>
              <p>
                <span class='slot_item'>2 </span>
                <i class='${slotInfo[1] == 1 ? 'one' : ''} ${slotInfo[1] == 2 ? 'two' : ''} ${slotInfo[1] == 3 ? 'three' : ''}'></i>
                <span class='carrier_name ellipsis'>${realTimeParams.slotDetail && realTimeParams.slotDetail[1].carrierName || this.$t('lang.noDevice')}</span>
                <span class='slot_bit'>${realTimeParams.slotDetail && realTimeParams.slotDetail[1].speed || 0} kbps</span>
                <span class=''>${realTimeParams.slotDetail && realTimeParams.slotDetail[1].rttInMsec || ''}</span>
              </p>
              <p>
                <span class='slot_item'>3 </span>
                <i class='${slotInfo[2] == 1 ? 'one' : ''} ${slotInfo[2] == 2 ? 'two' : ''} ${slotInfo[2] == 3 ? 'three' : ''}'></i>
                <span class='carrier_name ellipsis'>${realTimeParams.slotDetail && realTimeParams.slotDetail[2].carrierName || this.$t('lang.noDevice')}</span>
                <span class='slot_bit'>${realTimeParams.slotDetail && realTimeParams.slotDetail[2].speed || 0} kbps</span>
                <span class=''>${realTimeParams.slotDetail && realTimeParams.slotDetail[2].rttInMsec || ''}</span>
              </p>
              <p>
                <span class='slot_item'>4 </span>
                <i class='${slotInfo[3] == 1 ? 'one' : ''} ${slotInfo[3] == 2 ? 'two' : ''} ${slotInfo[3] == 3 ? 'three' : ''}'></i>
                <span class='carrier_name ellipsis'>${realTimeParams.slotDetail && realTimeParams.slotDetail[3].carrierName || this.$t('lang.noDevice')}</span>
                <span class='slot_bit'>${realTimeParams.slotDetail && realTimeParams.slotDetail[3].speed || 0} kbps</span>
                <span class=''>${realTimeParams.slotDetail && realTimeParams.slotDetail[3].rttInMsec || ''}</span>
              </p>
              <p>
                <span class='slot_item'>5 </span>
                <i class='${slotInfo[4] == 1 ? 'one' : ''} ${slotInfo[4] == 2 ? 'two' : ''} ${slotInfo[4] == 3 ? 'three' : ''}'></i>
                <span class='carrier_name ellipsis'>${realTimeParams.slotDetail && realTimeParams.slotDetail[4].carrierName || this.$t('lang.noDevice')}</span>
                <span class='slot_bit'>${realTimeParams.slotDetail && realTimeParams.slotDetail[4].speed || 0} kbps</span>
                <span class=''>${realTimeParams.slotDetail && realTimeParams.slotDetail[4].rttInMsec || ''}</span>
              </p>
              <p>
                <span class='slot_item'>6 </span>
                <i class='${slotInfo[5] == 1 ? 'one' : ''} ${slotInfo[5] == 2 ? 'two' : ''} ${slotInfo[5] == 3 ? 'three' : ''}'></i>
                <span class='carrier_name ellipsis'>${realTimeParams.slotDetail && realTimeParams.slotDetail[5].carrierName || this.$t('lang.noDevice')}</span>
                <span class='slot_bit'>${realTimeParams.slotDetail && realTimeParams.slotDetail[5].speed || 0} kbps</span>
                <span class=''>${realTimeParams.slotDetail && realTimeParams.slotDetail[5].rttInMsec || ''}</span>
              </p>
            </div>
            <div>
              <i class='iconfont icon-zoomout icon_operation' onClick='closeSourceDetail()'></i>
            </div>
          </div>
        </div>
        <div class='map-al-form'>
          <div class="map-form-list">
            <div>
              <span class="iconfont icon-wifi ${sourceData.deviceName && 'hide'}"></span>
              <span class="signal-content ${sourceData.deviceName && 'hide'}">${singnalFormatter}</span>  
              <span class='${!sourceData.deviceName && 'hide'}'>
                <span class='iconfont'>&#xe641;</span>
                ${sourceData.deviceName}
              </span>
              <span class="iconfont icon-12-copy-copy f_right ${sourceData.deviceName && 'hide'}"" onClick="editMaker()"></span>
              <span class="iconfont icon-leave f_right ${sourceData.deviceName && 'hide'}"" onClick="deleteMaker()"></span>
            </div>
            <div>
              <span class="iconfont icon-locationgray"></span>
              <span>N ${makerPos.latitude} E ${makerPos.longitude}</span>
            </div>
            <div>
            <span style="margin-left:20px;" class="address-detail">${addressValue}</span>
            </div>
            <div>
              <span class="iconfont  icon-copy icon-remark"></span>
              <span class="remark-detail">${remark}</span>
            </div>
            <div class="edit-time">${strDate}</div>
        </div>
      </div>`
      return strInfo
    },
    hideDelete () {
      if ((Object.prototype.hasOwnProperty.call(this.windowDetail, 'realTimeParams') &&
          JSON.parse(this.windowDetail.realTimeParams).signalStrength === '2') ||
        (Object.prototype.hasOwnProperty.call(this.windowDetail, 'realTimeParams') &&
          JSON.parse(this.windowDetail.realTimeParams).signalStrength === '3')) {
        // 设置删除按钮不显示
        $('.icon-leave').css('display', 'none')
      } else if (JSON.parse(this.windowDetail.realTimeParams).signalStrength == '') {
        // 设置删除按钮不显示
        $('.icon-leave').css('display', 'none')
      } else if (!Object.prototype.hasOwnProperty.call(this.windowDetail, 'realTimeParams')) {
        // 设置删除按钮不显示
        $('.icon-leave').css('display', 'none')
      }
      if (this.windowDetail.signalStrength) {
        // 设置删除按钮不显示
        $('.icon-leave').css('display', 'block')
      }
      switch (this.singnalString) {
        case 'Bad':
          $('.signal-content').css({ color: '#FFB700;' })
          break
        case 'Good':
          $('.signal-content').css({ color: '#1EC345;' })
          break
        case 'very bad':
          $('.signal-content').css({ color: '#E34D51;' })
          break
        case 'worse':
          $('.signal-content').css({ color: '#F07D03;' })
          break
        default:
          // 这里是没有找到对应的值处理
          break
      }
    },
    // 保存点
    saveMaker (lng, lat) {
      let formData = {}
      let pos = {
        longitude: lng,
        latitude: lat
      }
      formData = {
        signalStrength: $('.map-form-list select').val() && $('.map-form-list select').val().trim(),
        address: $('.map-al-form .form-input').val().trim(),
        remark: $('.map-al-form .form-textarea').val().trim()
      }
      this.savePoint(this.mapSourceData, formData, pos)
    },
    // 编辑maker
    editMaker () {
      let data = this.formDetail
      let strFirst = ''
      let flag = this.singnalString

      if (flag === 'Good' || flag === 'Bad') {
        let selectValue = flag === 'Good'
        strFirst = `<div>
                      <span class="iconfont icon-wifi"></span>
                      <select class='select-form'>
                        <option style="color:#FFB700" value="1" ${!selectValue ? 'selected' : ''}>${this.$t('lang.bad')}</option>
                        <option style="color:#1EC345" value="0" ${selectValue ? 'selected' : ''}>${this.$t('lang.good')}</option>
                      </select>
                    </div>`
      } else if (flag === 'very bad') {
        strFirst = `<div>
                      <span class="iconfont icon-wifi"></span>
                      <span style="color:#E34D51">${this.$t('lang.veryBad')}</span>
                    </div>`
      } else if (flag === 'worse') {
        strFirst = `<div>
                      <span class="iconfont icon-wifi"></span>
                      <span style="color:#F07D03">${this.$t('lang.worse')}</span>
                    </div>`
      }
      let str = strFirst + `<div>
                              <span class="iconfont icon-locationgray"></span>
                              <span>N ${data.makerPos.latitude} E ${data.makerPos.longitude}</span>
                            </div>
                            <div>
                              <input type="text" placeholder=${this.$t('lang.address')} class="form-input" maxlength="255" />
                            </div>
                            <div>
                              <span class="iconfont  icon-copy icon-remark" style="top:-54px;"></span>
                              <textarea placeholder=${this.$t('lang.remark')} rows="3" cols="20" class="form-textarea" maxlength="255">${data.remark}</textarea>
                            </div>
                            <div class="footer-button">
                              <button class="map-cancel"  onClick="cancelModal()">${this.$t('lang.cancel')}</button>
                              <button class="map-save" onClick="saveMaker(${data.makerPos.longitude},${data.makerPos.latitude})"> ${this.$t('lang.save')}</button>
                            </div>
                          `
      $('.map-form-list').html(str)
      // 设置input的值
      $('.form-input').val(this.formDetail.addressValue)
    },
    // 点击弹框取消
    cancelModal () {
      $('.BMap_pop').hide()
    },
    // 点击删除marker
    deleteMaker () {
      // 删除掉匹配的手动标记点
      for (let i = 0; i < this.allPositionData.length; i++) {
        if (this.saveTime == this.allPositionData[i].timestamp) {
          this.allPositionData.splice(i, 1)
        }
      }

      for (let i = 0; i < this.allMarkData.length; i++) {
        if (this.saveTime == this.allMarkData[i].timestamp) {
          this.allMarkData.splice(i, 1)
        }
      }

      for (let i = 0; i < this.manualOrange.length; i++) {
        if (this.saveTime == this.manualOrange[i].timestamp) {
          this.manualOrange.splice(i, 1)
        }
      }

      for (let i = 0; i < this.manualGreen.length; i++) {
        if (this.saveTime == this.manualGreen[i].timestamp) {
          this.manualGreen.splice(i, 1)
        }
      }
      $('.BMap_pop').hide()
      let slotinfo = []
      this.sourceDataObj.slotinfo &&
        this.sourceDataObj.slotinfo.AdapterModels.map((v) => {
          slotinfo.push(v.Status)
        })
      let QualityMeasureOnGUI = this.sourceDataObj.receiverdata.QualityMeasureOnGUI
      if (QualityMeasureOnGUI === 0) {
        QualityMeasureOnGUI = String(QualityMeasureOnGUI)
      }

      let sourceData = {
        peerId: this.$route.query.peerId,
        bitrate: (this.sourceDataObj.setinfo.CurrentSetting.Bitrate && String(this.sourceDataObj.setinfo.CurrentSetting.Bitrate)) || '',
        longitude: this.mapSourceData.longitude || '',
        latitude: this.mapSourceData.latitude || '',
        realTimeParams: JSON.stringify({
          QualityMeasureOnGUI: QualityMeasureOnGUI || '',
          type: this.sourceDataObj.currentrinfo.CurrentTInputType.ReadableName,
          format: this.sourceDataObj.currentrinfo.CurrentTFormat.ReadableName,
          delay: this.getDelay(
            (this.sourceDataObj.setinfo.CurrentSetting.Delay / 1000).toFixed(1),
            this.sourceDataObj.setinfo.CurrentTCanSetDelay
          ),
          error: this.sourceDataObj.receiverdata.ErrorRateLastSec,
          quality: this.sourceDataObj.receiverdata.ReSendRateLastSec,
          slotInfo: slotinfo,
          img: '',
          signalStrength: '',
          voiceInfo: this.sourceDataObj.volumechart,
          status: this.currentSource.status
        }),
        signalStrength: '',

        timestamp: this.saveTime,
        slotDetail: this.slotDetail
      }
      if (sourceData.realTimeParams && sourceData.realTimeParams.length >= 512) return
      this.axios.post('/ccp/tvucc-device/geoRecord/saveGeoRecord', sourceData, {
        headers: { 'Content-Type': 'application/json;charset=UTF-8' }
      }).then(async (res) => {
        if (res.data.errorCode == '0x0') {
          // 更新本地的allMarkdata
          let markType = 'add'
          for (let val of this.allMarkData) {
            if (val.timestamp == sourceData.timestamp) {
              val = sourceData
              markType = 'edit'
              break
            }
          }
          // 如果找不到数据就添加
          if (markType == 'add') {
            this.allMarkData.push(sourceData)
          }
          return true
        }
        return false
      }).catch(() => {
        return false
      })
      // 重新画线
      if (this.historyFlag) {
        this.getHistory()
      } else {
        this.makeLine()
      }
      let centerPoint = new BMap.Point(this.mapSourceData.longitude, this.mapSourceData.latitude)

      this.map.setCenter(centerPoint)
    },
    async makeLine (val) {
      if (val) {
        if (val.timeType === '0') {
          this.params.starttime = this.interval
          this.params.mapType = 'baidu';
          (this.params.endtime || this.params.endtime === 0) && delete this.params.endtime
        }
      } else {
        if (this.$route.query.timeType === '0') {
          this.params.starttime = this.interval
          this.params.mapType = 'baidu';
          (this.params.endtime || this.params.endtime === 0) && delete this.params.endtime
        }
      }
      await this.axios.get(`${this.url}/Out/getGPSTrackingServlet`, { params: this.params },
        { headers: { 'Content-Type': 'application/json;charset=UTF-8' } }).then(async (res) => {
        if (!res.data || (typeof res.data == 'string' && !res.data.trim())) return
        if (this.$route.query.timeType === '0') {
          // 如果返回了手动设置的点，比较返回时间与当前时间是否相差超过一定范围
          let incrementalRangeTime = localStorage.getItem('incrementalRangeTime')
          let manualIncrementTime = localStorage.getItem('manualIncrementTime')
          let currentGps = res.data.trim().split('\r\n')
          let currentTime = new Date().getTime()
          // let currentTime = 1604911131866;
          let removeGps = []
          // currentGps.map((v, i) => {
          for (let i = 0; i < currentGps.length; i++) {
            let v = currentGps[i]
            if (v.trim() === '') {
              removeGps.push(i)
              continue
            }
            let currentData = v.split(',')
            // 返回时间与当前时间相差超过一定范围
            if (Math.abs(currentTime - currentData[3]) > incrementalRangeTime) {
              // 返回时间-固定值的差值是否在一定范围内，如果不在，则去掉这个点
              if (Math.abs(currentTime - Math.abs(currentData[3] - manualIncrementTime)) > incrementalRangeTime) {
                removeGps.push(i)
              } else {
                // 在则更改这个点的时间戳
                currentData[3] = String(currentData[3] - manualIncrementTime)
                currentGps[i] = currentData.join(',')
              }
            }
          }
          let removeNum = 0
          removeGps.map((v) => {
            currentGps.splice(v - removeNum, 1)
            removeNum++
          })
          if (!currentGps.length) return
          res.data = currentGps.join('\r\n')
        }

        let infos = null
        if (res.data !== '') {
          infos = res.data.trim().split('\r\n')
        } else {
          return
        }

        // 间隔10秒及其以上
        let filterInfo = []
        // infos.map((item) => {
        for (let i = 0; i < infos.length; i++) {
          let item = infos[i]
          if (typeof item && item.trim() === '') {
            continue
          }
          let v = item.split(',')
          let lastPoint = this.firstData[this.firstData.length - 1] && this.firstData[this.firstData.length - 1].split(',')
          if (!filterInfo.length) {
            if (!lastPoint || v[3] - lastPoint[3] >= this.spaceTime) {
              filterInfo.push(v.join(','))
            }
            continue
          }
          if (v[3] - filterInfo[filterInfo.length - 1][3] >= this.spaceTime) {
            filterInfo.push(v.join(','))
          }
        }
        // 如果返回的实时点不满足间隔10秒的条件，return
        if (!filterInfo.length) return
        infos = JSON.parse(JSON.stringify(filterInfo))
        // 根据时间戳保存source详情
        if (this.currentSource.status == 2 && this.$route.query.timeType == '0') {
          let lastPointData = ''
          for (let j = infos.length - 1; j >= 0; j--) {
            if (infos[j].trim() != '') {
              lastPointData = infos[j]
              break
            }
          }
          // 如果源不在线了，停止保存
          if (res.data && infos.length) {
            await this.saveSourceData(lastPointData.split(','))
          }
        }

        if (this.firstData.length !== 0 && this.firstFlag) {
          if (this.firstData[this.firstData.length - 1] !== infos[0]) {
            infos.unshift(this.firstData[this.firstData.length - 1])
          } else {
            for (var i = this.firstData.length - 2; i > 0; i--) {
              if (this.firstData[i] !== infos[0]) {
                infos.unshift(this.firstData[i])
                break
              }
            }
          }
        }
        this.firstFlag++

        this.firstData = this.firstData.concat(filterInfo)
        // 间隔时间为10秒以上的才显示点
        let gpsPoints = [] // gps返回的点
        let filterGpsArr = []
        this.firstData.map((v) => {
          if (!v) return
          let gpsPoint = v.split(',')
          gpsPoints.push({
            longitude: gpsPoint[1],
            latitude: gpsPoint[0],
            timestamp: gpsPoint[3]
          })
          if (!filterGpsArr.length) {
            filterGpsArr.push(v)
          }
          if (v.split(',')[3] - filterGpsArr[filterGpsArr.length - 1].split(',')[3] >= this.spaceTime) {
            filterGpsArr.push(v)
          }
        })
        this.firstData = filterGpsArr

        let allPoints = gpsPoints.concat(this.allMarkData)

        allPoints.sort((m, n) => {
          return m.timestamp - n.timestamp
        })
        let filterPoints = []
        allPoints.map((v, i) => {
          if (!filterPoints.length) {
            filterPoints.push(v)
          }
          if (v.timestamp - filterPoints[filterPoints.length - 1].timestamp == 0 && v.id) {
            filterPoints[filterPoints.length - 1] = v
          }
          if (v.timestamp - filterPoints[filterPoints.length - 1].timestamp >= this.spaceTime) {
            filterPoints.push(v)
          }
        })
        this.allPositionData = filterPoints
        var points = []
        this.timestamps = []

        for (let i in infos) {
          if (infos[i].trim() == '') {
            continue
          }
          var ainfo = infos[i].split(',')

          points.push(new BMap.Point(Number(ainfo[1]), Number(ainfo[0])))
          this.timestamps[i] = ainfo[3] + '_' + 0
        }
        let marker = []
        var pointList = [] // 点
        let infoModal = []
        if (infos.length !== 0 && infos !== '') {
          this.startNum++
        }
        for (let i in infos) {
          ainfo = infos[i].split(',')
          if (Number(ainfo[1]) <= 180 && Number(ainfo[0]) <= 90) {
            let point = points[i]
            pointList.push(point)
            if (i !== 0) {
              var myIcon = new BMap.Icon(
                require('@/assets/img/map/map_point.svg'),
                new BMap.Size(4, 4)
              )
              marker[i] = new BMap.Marker(point, { icon: myIcon }) // 创建标注
            }

            // 添加唯一标识
            marker[i].timestamp = ainfo[3]
            this.map.addOverlay(marker[i])
            // 终点
            if (i == infos.length - 1 && infos.length - 1 !== 0) {
              if (this.iconType === 'red') {
                let iconPoint = new BMap.Icon(
                  require('@/assets/img/map/location_red.svg'),
                  new BMap.Size(32, 32)
                )
                marker[i] = new BMap.Marker(point, { icon: iconPoint }) // 创建标注
                marker[i].timestamp = ainfo[3]
                this.map.addOverlay(marker[i])
              } else if (this.iconType === 'orange') {
                let iconPoint = new BMap.Icon(
                  require('@/assets/img/map/location_orange.svg'),
                  new BMap.Size(32, 32)
                )
                marker[i] = new BMap.Marker(point, { icon: iconPoint }) // 创建标注
                marker[i].timestamp = ainfo[3]
                this.map.addOverlay(marker[i])
              } else {
                this.endPoints.push(points[i])
              }
            }
            // 起点
            if (i === '0') {
              this.startPoints.push(points[i])
            }

            let that = this
            marker[i].addEventListener('click', async function (e) {
              let position = {
                y: e.point.lng,
                x: e.point.lat
              }
              let timestamp = null
              if (that.allMarkData.length !== 0) {
                timestamp = that.getTimestamp(
                  position,
                  that.allMarkData,
                  true
                )
                if (!timestamp) {
                  timestamp = that.getTimestamp(
                    position,
                    that.allPositionData
                  )
                }
              } else {
                timestamp = that.getTimestamp(position, that.allPositionData)
              }

              let strInfo = await that.openSourceDetail(timestamp, position)
              infoModal[i] = new window.BMap.InfoWindow(strInfo)
              await this.openInfoWindow(infoModal[i]) // 悬浮监听提示方法

              setTimeout(() => {
                // 系统自动打点设置删除按钮不显示
                that.hideDelete()
              }, 0)
            })
          }
        }
        // 创建起点
        if (this.startNum === 1) {
          let iconStart = new BMap.Icon(
            require('@/assets/img/map/start_point.svg'),
            new BMap.Size(32, 32)
          )
          let markerS = new BMap.Marker(points[0], {
            icon: iconStart
          }) // 创建标注

          this.map.addOverlay(markerS)
          markerS.addEventListener('click', async function (e) {
            let position = {
              y: e.point.lng,
              x: e.point.lat
            }
            let timestamp = null
            if (that.allMarkData.length !== 0) {
              timestamp = that.getTimestamp(position, that.allMarkData, true)
              timestamp = timestamp || that.getTimestamp(position, that.allPositionData)
            } else {
              timestamp = that.getTimestamp(position, that.allPositionData)
            }

            let strInfo = await that.openSourceDetail(timestamp, position)
            infoModal = new window.BMap.InfoWindow(strInfo)
            this.openInfoWindow(infoModal) // 悬浮监听提示方法
            setTimeout(() => {
              // 系统自动打点设置删除按钮不显示
              that.hideDelete()
            }, 0)
          })
        }
        // 黄色的点
        if (this.manualOrange.length !== 0) {
          let manualPointOrange = []
          for (let i in this.manualOrange) {
            let that = this
            let point = this.manualOrange[i]
            let icon = new BMap.Icon(
              require('@/assets/img/map/location_yellow.svg'),
              new BMap.Size(32, 32)
            )
            manualPointOrange[i] = new BMap.Marker(point, { icon: icon }) // 创建标注
            manualPointOrange[i].timestamp = ainfo[3]
            this.map.addOverlay(manualPointOrange[i])
            manualPointOrange[i].addEventListener('click', async function (e) {
              let position = {
                y: e.point.lng,
                x: e.point.lat
              }

              let timestamp = null
              if (that.allMarkData.length !== 0) {
                timestamp = that.getTimestamp(
                  position,
                  that.allMarkData,
                  true
                )
                if (!timestamp) {
                  timestamp = that.getTimestamp(
                    position,
                    that.allPositionData
                  )
                }
              } else {
                timestamp = that.getTimestamp(position, that.allPositionData)
              }

              let strInfo = await that.openSourceDetail(timestamp, position)
              infoModal[i] = new window.BMap.InfoWindow(strInfo)
              this.openInfoWindow(infoModal[i]) // 悬浮监听提示方法
              setTimeout(() => {
                // 系统自动打点设置删除按钮不显示
                that.hideDelete()
              }, 0)
            })
          }
        }

        // 绿色的点
        if (this.manualGreen.length !== 0) {
          let manualPointGreen = []
          for (let i in this.manualGreen) {
            let point = this.manualGreen[i]
            let icon = new BMap.Icon(
              require('@/assets/img/map/location_green.svg'),
              new BMap.Size(32, 32)
            )
            manualPointGreen[i] = new BMap.Marker(point, { icon: icon }) // 创建标注
            manualPointGreen[i].timestamp = ainfo[3]
            this.map.addOverlay(manualPointGreen[i])
            manualPointGreen[i].addEventListener('click', async function (e) {
              let position = {
                y: e.point.lng,
                x: e.point.lat
              }

              let timestamp = null
              if (that.allMarkData.length !== 0) {
                timestamp = that.getTimestamp(
                  position,
                  that.allMarkData,
                  true
                )
                if (!timestamp) {
                  timestamp = that.getTimestamp(
                    position,
                    that.allPositionData
                  )
                }
              } else {
                timestamp = that.getTimestamp(position, that.allPositionData)
              }

              let strInfo = await that.openSourceDetail(timestamp, position)
              infoModal[i] = new window.BMap.InfoWindow(strInfo)
              this.openInfoWindow(infoModal[i]) // 悬浮监听提示方法
              setTimeout(() => {
                // 系统自动打点设置删除按钮不显示
                that.hideDelete()
              }, 0)
            })
          }
        }

        if (!this.map.getInfoWindow()) {
          this.map.setCenter(pointList[pointList.length - 1])
        }

        var sy = new BMap.Symbol('BMap_Symbol_SHAPE_BACKWARD_OPEN_ARROW', {
          scale: 0.6, // 图标缩放大小
          strokeColor: '#fff', // 设置矢量图标的线填充颜色
          strokeWeight: '2' // 设置线宽
        })
        var icons = new BMap.IconSequence(sy, '10', '60')

        var polyline = new BMap.Polyline(pointList, {
          enableEditing: false, // 是否启用线编辑，默认为false
          enableClicking: true, // 是否响应点击事件，默认为true
          strokeWeight: '4', // 折线的宽度，以像素为单位
          strokeOpacity: 1, // 折线的透明度，取值范围0 - 1
          icons: [icons],
          strokeColor: '#18A45B' // 折线颜色
        })
        this.map.addOverlay(polyline)
        // 添加线的点击事件
        let that = this
        polyline.addEventListener('click', async function (e) {
          let position = {
            y: e.point.lng,
            x: e.point.lat
          }
          let timestamp = null
          if (that.allMarkData.length !== 0) {
            timestamp = that.getTimestamp(position, that.allMarkData, true)
            if (!timestamp) {
              timestamp = that.getTimestamp(position, that.allPositionData)
            }
          } else {
            timestamp = that.getTimestamp(position, that.allPositionData)
          }
          // 修改线的点击事件，此处加点
          var point = new BMap.Point(e.point.lng, e.point.lat)
          let strInfo = await that.openSourceDetail(timestamp, position)

          var infoModal = new window.BMap.InfoWindow(strInfo)
          // 修改打开窗口事件，添加point  将this.openInfoWindow改为this.map.openInfoWindow
          await this.map.openInfoWindow(infoModal, point)
          this.map.addOverlay(polyline)
          setTimeout(() => {
            // 系统自动打点设置删除按钮不显示
            that.hideDelete()
          }, 0)
        })
      }).catch(() => {})
    },
    //  初始化中心点坐标
    async initCenter (val) {
      // 初始化地图，设置中心点坐标和地图级别 初始化地图，设置中心点坐标和地图级别
      this.axios.get(`${this.url}/Out/getGPSBypeeridAndTimestampServlet?peerid=${(val && val.peerId) || this.$route.query.peerId}`).then((res) => {
        if (res.status == 200 && res.data) {
          let position = res.data[0]
          this.map.centerAndZoom(
            new BMap.Point(
              Number(position.longitude),
              Number(position.latitude)
            ), 11)
          return
        }
        var point = new BMap.Point(121.479048, 31.240008) // 创建点坐标
        this.map.centerAndZoom(point, 11) // 初始化地图，设置中心点坐标和地图级别
      }).catch((res) => {
        var point = new BMap.Point(121.479048, 31.240008) // 创建点坐标
        this.map.centerAndZoom(point, 11) // 初始化地图，设置中心点坐标和地图级别
      })
    },
    // 匹配经纬度
    getTimestamp (position, infos, isMarkData) {
      // 精确匹配
      for (let i = 0; i < infos.length; i++) {
        let dataObj = infos[i]
        if (Number(dataObj.latitude) === Number(position.x) && Number(dataObj.longitude) === Number(position.y)) {
          return dataObj.timestamp
        }
      }
      let differArr = []
      // 模糊匹配
      for (let j = 0; j < infos.length; j++) {
        let dataObj = infos[j]
        let differItem = {
          differ: this.getDistance(
            dataObj.latitude,
            dataObj.longitude,
            position.x,
            position.y
          ),
          time: dataObj.timestamp
        }
        differArr.push(differItem)
      }
      differArr.sort((m, n) => {
        return m.differ - n.differ
      })
      if (isMarkData) {
        return differArr[0] && differArr[0].differ <= 1 && differArr[0].time
      }
      return differArr[0] && differArr[0].time
    },
    getRad (d) {
      var PI = Math.PI
      return (d * PI) / 180.0
    },
    getDistance (lat1, lng1, lat2, lng2) {
      lat1 = Number(lat1)
      lng1 = Number(lng1)
      lat2 = Number(lat2)
      lng2 = Number(lng2)
      var f = this.getRad((lat1 + lat2) / 2)
      var g = this.getRad((lat1 - lat2) / 2)
      var l = this.getRad((lng1 - lng2) / 2)
      var sg = Math.sin(g)
      var sl = Math.sin(l)
      var sf = Math.sin(f)
      var s, c, w, r, d, h1, h2
      var a = 6378137.0 // The Radius of eath in meter.
      var fl = 1 / 298.257
      sg = sg * sg
      sl = sl * sl
      sf = sf * sf
      s = sg * (1 - sl) + (1 - sf) * sl
      c = (1 - sg) * (1 - sl) + sf * sl
      w = Math.atan(Math.sqrt(s / c))
      r = Math.sqrt(s * c) / w
      d = 2 * w * a
      h1 = (3 * r - 1) / 2 / c
      h2 = (3 * r + 1) / 2 / s
      s = d * (1 + fl * (h1 * sf * (1 - sg) - h2 * (1 - sf) * sg))
      s = s / 1000
      s = s.toFixed(2) // 指定小数点后的位数。
      return s
    },
    // 处理坐标不正确的问题
    async tranlatePoint (points, call) {
      var tempArr = [] // 转换完成后的坐标数组
      var total = 0 // 总记录数
      var groupCount = 0 // 每次转十条
      if (points.length % 10 > 0) {
        groupCount = Math.floor(points.length / 10) + 1
      } else {
        groupCount = points.length / 10
      }
      for (let i = 0; i < groupCount; i++) {
        // 外层循环，有多少组十条
        var pos = []
        for (var j = 0; j < 10; j++) {
          // 内层循环，每组十条
          if (total < points.length) {
            // 不超过总记录数结束
            var point = new BMap.Point(
              points[i * 10 + j].lng,
              points[i * 10 + j].lat
            )
            pos.push(point)
          }
          total++
        }
        var convertor = new BMap.Convertor()
        await convertor.translate(pos, 3, 5, function (data) {
          if (data.status === 0) {
            for (var k = 0; k < data.points.length; k++) {
              tempArr.push(data.points[k])
            }
            if (i == groupCount - 1) {
              setTimeout(() => {
                call(tempArr)
              }, 500)
            }
          }
        })
      }
    },
    extendSourceDetail () {
      $('.device_detail').addClass('hide')
      $('.audio_column').addClass('hide')
      $('.date_box').addClass('hide')
      $('.extend_detail').removeClass('hide')
    },
    closeSourceDetail () {
      $('.device_detail').removeClass('hide')
      $('.audio_column').removeClass('hide')
      $('.date_box').removeClass('hide')
      $('.extend_detail').addClass('hide')
    }
  },
  // 路由离开之前先关闭定时器
  beforeRouteLeave (to, from, next) {
    sessionStorage.removeItem('collect')
    if (this.timer) {
      clearInterval(this.timer)
    }
    if (this.timeOut) {
      clearInterval(this.timeOut)
    }
    if (this.initBaiduTimers) {
      clearInterval(this.initBaiduTimers)
    }
    if (this.initTimers) {
      clearInterval(this.initTimers)
    }
    if (this.$createWebsocket.pageRequest) {
      this.$createWebsocket.pageRequest('stop', ['taskInfo_T_S', 'detailInfo']) // 停止websocket
    }
    if (this.$createWebsocket.pageRWebsocket) {
      this.currentSource.status = null
      this.iconType = ''
      this.$createWebsocket.pageRWebsocket('stop', [
        'VolumeChart',
        'slotInfo',
        'receiverData',
        'currentRInfo',
        'setInfo'
      ]) // 停止websocket
    }
    next()
  }
}
</script>
<style lang="less">
canvas {
  width: auto !important;
  max-width: none !important;
}
.device_icon_name {
  width: 100px;
  height: 21px;
  line-height: 21px;
  color: #000;
  background: #fff;
}
.map-content.map_gps {
  height: 205px;
  width: 614px;
  background-color: #161616;
  color: #cccccc;
  text-align: left;
  p {
    margin: 0;
  }
  .hide {
    display: none !important;
  }
  div {
    display: block !important;
  }
  select {
    height: 22px;
    line-height: 18px;
    padding: 2px 0;
  }
  .vedio-msg {
    width: 364px;
    position: relative;
    .img-map {
      display: inline-block;
      width: 100%;
    }
  }
  .voiclM {
    -webkit-transform: scale(1.4, 1.4);
    bottom: 40px !important;
  }
  .voiclL {
    -webkit-transform: scale(2, 2);
    bottom: 100px !important;
  }
  .liveBg {
    background: rgba(255, 51, 0, 1) !important;
    opacity: 0.76;
  }
  .t_thumbnail_view_item_icon {
    position: absolute;
    top: 0.16rem;
    left: 16px;
    width: 60px;
    height: 28px;
    border: none;
    background: #1ec345;
    border-radius: 4px;
    line-height: 28px;
    text-align: center;
  }
  .audio_column {
    position: absolute;
    bottom: 21px;
    left: 0;
    height: 128px;
    width: 47px;
    padding-left: 36px;
    .audio_scale {
      height: 100%;
      width: 2px;
      background-color: #999;
    }
    .audio_scale_num {
      width: 3px;
      height: 2px;
      background-color: #999;
      margin-left: -2px;
      margin-bottom: 7.7px;
      font-size: 12px;
      position: relative;
      span {
        position: absolute;
        top: -4px;
        left: -22px;
      }
      .scale_0 {
        left: -11px;
        top: -7px;
      }
      .scale_minus_12 {
        left: -20px;
        top: -8px;
      }
      .scale_minus_20 {
        left: -19.5px;
        top: -7px;
      }
      .scale_minus_40 {
        left: -20px;
        top: -12px;
      }
    }
    .audio_col {
      position: absolute;
      bottom: 62px;
      left: -26px;
      transform: rotate(-90deg);
      height: 4px;
      width: 128px;
      margin-left: 4px;
    }
    .volume_bar {
      background-color: #666;
      height: 3px;
      margin-top: 2px;
    }
    .volume_bar:first-child {
      margin-top: 0;
    }
    .progress-bar {
      background-color: #33ab4f;
    }
  }
  .date_box {
    position: absolute;
    top: 8px;
    right: 20px;
    font-size: 14px;
    color: #dbdbdb;
    span {
      padding-left: 8px;
    }
  }
  .orange {
    color: #ff9000;
  }
  .flashRed {
    color: #ff0000;
    -webkit-animation: twinkling 0.5s infinite ease-in-out;
  }
  .extend_detail {
    width: 100%;
    height: 205px;
    position: absolute;
    left: 0;
    top: 0;
    color: #f0f0f0;
    background: rgba(0, 0, 0, 0.5);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5), 0px 0px 1px 0px rgba(0, 0, 0, 0.32);
    border-radius: 2px 0px 0px 2px;
    font-size: 12px;
    padding: 10px;
    .source_detail p {
      float: left;
      width: 50%;
      font-size: 12px;
      padding-bottom: 3px;
    }
    .slot_title {
      border-bottom: 1px solid #999;
      margin-bottom: 3px;
      padding-bottom: 3px;
      .carrier_name {
        margin-left: 13px;
      }
    }
    .slot_detail {
      margin-top: 8px;
      .slot_item {
        display: inline-block;
        width: 31px;
      }
      .carrier_name {
        display: inline-block;
        width: 90px;
        vertical-align: middle;
        margin-right: 31px;
      }
      .slot_bit {
        display: inline-block;
        width: 78px;
        margin-right: 16px;
      }
      >p {
        i {
          display: inline-block;
          margin-right: 3px;
        }
      }
    }
    .icon_operation {
      position: absolute;
      right: 10px;
      bottom: 0;
      color: #ccc;
      font-size: 30px;
    }
  }
  .device_detail {
    width: 170px;
    height: 162px;
    position: absolute;
    // top: -148px;
    bottom: 0;
    // left: 240px;
    right: 0;
    color: #f0f0f0;
    font-size: 12px;
    line-height: 20px;
    background: rgba(0, 0, 0, 0.32);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5),
      0px 0px 1px 0px rgba(0, 0, 0, 0.32);
    border-radius: 2px 0px 0px 2px;
    padding: 5px 10px;
  }
  .edit-time {
    font-size: 12px;
    font-weight: 400;
    color: #666666;
    position: relative;
    bottom: -51px;
    left: 120px;
    width: calc(100% - 120px);
  }

  .remark-detail {
    width: 194px;
    max-height: 60px;
    overflow-y: scroll;
    min-height: 30px;
    display: inline-block;
    word-break: break-all;
  }
  .address-detail {
    width: 194px;
    max-height: 40px;
    overflow-y: scroll;
    min-height: 30px;
    display: inline-block;
    word-break: break-all;
  }
  .slot {
    .card-info {
      // width: 75%;
      width: 56%;
      height: 45px;
      margin: 0;
      padding: 0;
      padding-top: 13px;
      position: absolute;
      bottom: 0;
      left: 10px;
    }
    li {
      display: block;
      width: 27px;
      float: left;
      text-align: center;
      margin-bottom: 9px;
    }
    i {
      width: 9px;
      height: 9px;
      display: block;
      border-radius: 100%;
      background: #666;
    }
    i.one {
      background: #ff3300;
    }
    i.two {
      background: rgb(255, 144, 0);
    }
    i.three {
      background: #33ab4f;
    }
  }
  .icon_operation {
    position: absolute;
    right: 10px;
    bottom: 5px;
    color: #ccc;
    font-size: 30px;
  }
  .map-al-form {
    position: relative;
    top: -198px;
    left: 370px;
    width: 238px;
    height: 205px;
    select,
    input,
    textarea {
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
      background: #161616;
      border-radius: 4px;
      border: 1px solid #666666;
      width: 198px;
      height: 28px;
      margin: 3px auto;
      resize: none;
    }
    textarea {
      height: 67px;
    }
    input {
      margin-left: 20px;
    }

    .icon-remark {
      position: relative;
      top: -13px;
    }
    .signal-content {
      width: 170px;
      display: inline-block;
    }
    .footer-button {
      text-align: right;
      margin-right: 34px;
    }
    button {
      width: 64px;
      height: 24px;
      border-radius: 4px;
      border: none;
    }
    .map-save {
      background: #00cc66;
      color: #333333;
      font-size: 12px;
      color: #ffffff;
      line-height: 14px;
    }

    .map-cancel {
      background: #cccccc;
      color: #333333;
      font-size: 12px;
      line-height: 14px;
    }
  }
}
</style>
<style lang="less" scoped>
p {
  margin: 0;
}
#mapContent {
  height: 100%;
  width: 100%;
  ::v-deep .content-box {
    width: auto;
    height: auto;
  }
}
</style>
